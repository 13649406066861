import {isLoggedIn} from '../router/guards'
import {createRouter, createWebHashHistory} from 'vue-router'
import Runs from "./runs";
import Auth from "./auth"
import Statistics from './statistics'
import Modifications from "./modifications";
import Participants from "./participants";
import Settings from "./settings";
import Axs from "./axs"
import ApiDocu from "./apiDocu"
import Print from "./print"
import Obus from "./obus"
import RaceInfos from "./raceInfo"
import Busdriver from "./busdriver";
import Result from "./result";
import Announcer from "./announcer";
import store from '../store'
import Gate from "@/permissions/Gate";
import globalConfigs from "./globalConfigs";
import Labels from "@/router/labels";
import CustomReport from "@/router/customReport";
import Voucher from "@/router/voucher";
import Sponsors from './sponsors';
import Donations from "@/router/donations";
import Tracks from './tracks'
import Team from "@/router/teams";
import Accounting from "@/router/accounting";
import Users from "@/router/users";
import CommunityRuns from "@/router/communityRuns";
import Events from "@/router/events";
import LeaderDevices from "@/router/leaderdevices";
import Admin from "@/router/admin";
import EmailCampaigns from "@/router/emailCampaigns";
import External from "@/router/external";
import Groups from "@/router/groups";

const routes = [
  {
    path: '',
    name: 'home',
    beforeEnter: [isLoggedIn, (to, from, next) => {
      const gate = new Gate({store})
      const prefferedStartRoute = [
          'runs',
          'statistics.home',
      ]

      for( const routeName of prefferedStartRoute ) {
        if (gate.hasOne([`wflwr.manage.route.${routeName}`])) {
          next({name: routeName})
          return
        }
      }
    }],
    component: () => import('../layout/default/Default'),
  },
  {
    path: '/',
    component: () => import('../layout/default/Default'),
    beforeEnter: isLoggedIn,
    children: [
      {
        path: 'statistic',
        name: 'statistic',
        component: () => import('../applications/Statistic/Statistic.vue'),
      },
    ]
  },
  {
    path: '/',
    component: () => import('../layout/default/Default'),
    beforeEnter: isLoggedIn,
    children: [
      {
        path: '',
        component: () => import('../layout/default/Absolute.vue'),
        children: [
          {
            path: 'race-map',
            name: 'race-map',
            component: () => import('../applications/RaceInfo/RaceMap.vue'),
          },
        ]
      }
    ]
  },
  ...Auth,
  ...Settings,
  ...Statistics,
  ...Runs,
  ...Modifications,
  ...Participants,
  ...Labels,
  ...LeaderDevices,
  ...Axs,
  ...ApiDocu,
  ...Print,
  ...Obus,
  ...RaceInfos,
  ...Busdriver,
  ...Result,
  ...Announcer,
  ...CustomReport,
  ...globalConfigs,
  ...Voucher,
  ...Sponsors,
  ...Donations,
  ...Tracks,
  ...Team,
  ...Accounting,
  ...Users,
  ...CommunityRuns,
  ...Events,
  ...Admin,
  ...External,
  ...EmailCampaigns,
  ...Groups
]

export default createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
  routes
})
