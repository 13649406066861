export const rootClasses = function (sectionKey, node) {
    // this is an incomplete theme for demonstration purposes
    const type = node.props.type
    // create a classConfig object that contains either strings or functions
    // that return strings. We'll loop over the output later to create our
    // class objects of keys with boolean values.
    const classConfig = {
        outer () { // string values apply to everything
            return `${hasAttribute(node, 'full-width') ? 'w-full' : ''}`
        },
        legend: 'block mb-1 font-bold',
        label () { // functions that return strings allow you to diff on types
            if (type === 'checkbox') { return "ml-2 font-bold" }
            return 'text-md text-slate-700 ml-1 mb-1'
        },
        options () {
            if (type === 'radio') { return 'flex flex-col flex-grow mt-2' }
        },
        input () {
            let classes = ``
            if (['text','select','number', 'textarea', 'searchSelect', 'select2', 'email'].includes(type)) {
                classes = `w-full h-10 px-3 text-base text-gray-700 placeholder-gray-400 border border-gray-200 rounded-md focus:shadow-outline bg-transparent `
            }
            if (type === 'radio') {
                classes = 'mr-2 '
            }
            if (['button', 'submit'].includes(type)) {
                classes = `cursor-pointer h-10 px-3 text-white bg-cli-primary hover:bg-cli-primary-dark disabled:bg-slate-400 disabled:text-slate-200 disabled:cursor-not-allowed rounded `
            }
            return classes + applyDefaultAttributes(node, type)
        },
        wrapper () {
            if (type === 'radio') { return 'flex flex-row flex-grow' }
        },
        inner () {
            if (type === 'checkbox') { return "inline" }
        },
        help: 'text-xs text-gray-500',
        message: 'text-xs text-red-500'
    }

    const applyDefaultAttributes = (node, type) => {
        if (["submit", "button"].includes(type)) {
            return ` 
                    ${hasAttribute(node, 'error') ? 'bg-red-500 hover:bg-red-700' : ''} 
                    ${hasAttribute(node, 'secondary') ? 'bg-inherit border text-black hover:bg-cli-primary hover:text-white' : ''} 
                    ${hasAttribute(node, 'full-width') ? 'w-full' : ''} 
                `
        }
        return ``
    }

    const hasAttribute = (node, name) => node.props.attrs[name] !== undefined

    // helper function to created class object from strings
    function createClassObject (classesArray) {
        const classList = {}
        if (typeof classesArray !== 'string') return classList
        classesArray.split(' ').forEach(className => {
            classList[className] = true
        })
        return classList
    }

    const target = classConfig[sectionKey]
    // return a class objects from strings and return them for each
    // section key defined by inputs in FormKit
    if (typeof target === 'string') {
        return createClassObject(target)
    } else if (typeof target === 'function') {
        return createClassObject(target())
    }

    return {} // if no matches return an empty object
}
