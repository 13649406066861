import Model from '../Model'
import User from '../system/User'
import Participant from "../participant/Participant";
import Label from "@/models/label/Label";

export default class Run extends Model {
    static relationMapping = {
        'user': User,
        'labels': Label,
        'participant': Participant,
    }

    getDynamoInfo() {
        return this.api.dynamo(this.id)
    }

    publishIotMessage(type, payload) {
        return this.api.publishIotMessage(this.id, type, payload)
    }

    restoreArchive() {
        return this.api.restoreArchive(this.id)
    }
}
