export default class Model {
  static defaults = {}

  static relationMapping = {}

  constructor(data, included, api) {
    this.api = api;
    this.id = data.id;
    this.attributes = {
      // remove references to this.constructor.defaults ( deep copy of a object )
      // so all models get a fresh copy of defaults
      ...JSON.parse(JSON.stringify(this.constructor.defaults)),
      ...data.attributes
    }
    this.relationships = data.relationships

    this.linkRelations(included)

    /*
    Object.keys(this.attributes).forEach((key) => {
      this.addAttribute(key)
    })
    */
  }

  /**
   * Link related records
   * @param included
   * @returns {Model}
   */
  linkRelations(included = []) {
    if (this.relationships) {
      Object.entries(this.relationships)
        .forEach((tmp) => {
          const relationName = tmp[0]
          const relationData = tmp[1]
          const relationModel = this.constructor.relationMapping[relationName]

          if (!relationModel || !relationData) return

          if (Array.isArray(relationData)) {
            this.relationships[relationName] = relationData.map((data) => {
              if (!data.type || !data.id) {
                return data
              }

              const modelData = included.find((item) => item.id === data.id && item.type === data.type)
              return relationModel.create(modelData, included)
            })
          } else if (typeof relationData === 'object') {
            if (!relationData.type || !relationData.id) {
              return
            }

            const modelData = included.find((item) => item.id === relationData.id && item.type === relationData.type)
            this.relationships[relationName] = relationModel.create(modelData, included)
          }
        })
    }

    return this
  }

  /**
   * Add getters/setters for an attribute
   * @param {string} key
   * @returns {*}
   */
  addAttribute(key) {
    if (key in this) {
      return
    }
    Object.defineProperty(this, key, {
      get() {
        return this.attributes[key]
      },
      set(value) {
        this.attributes[key] = value
      }
    })
  }

  /**
   * Create a new model instance
   * @param data
   * @param included
   * @returns {Model}
   */
  static create(data, included, api) {
    return new this(data, included, api)
  }

  /**
   * Map models from an array
   * @param collection
   * @param included
   * @returns {Model[]}
   */
  static collection(collection, included) {
    return collection.map((item) => this.create(item, included))
  }

  /**
   * Return flattened Model with id and attributes
   * @returns {{[p: string]: *}}
   */
  flatten() {
    return {
      id: this.id,
      ...this.attributes
    }
  }

  static show(id){
    return this.api.show(id)
  }

  update(payload){
    return this.api.update(this.id, payload)
  }

}
