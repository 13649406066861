<template>
    <FormKit type="form" :actions="false">
        <FormKit type="text" :classes="context.classes" v-model="value" @focus="focused = true" readonly />
        <div class="mt-2 fixed bg-white w-full z-[99999] max-w-xs shadow-xl" v-if="focused" v-click-outside="() => focused = false">
            <div :class="`py-4 ${settings.dark ? 'bg-zinc-700' : 'bg-slate-50'} rounded-t rounded-b grid content-center z-50 disable-selection disable-outline`">

                <div class="flex justify-between content-center -mt-1.5">
                    <p :class="`ml-4 text-md ${settings.dark ? 'text-gray-100' : 'text-cli-primary'} font-bold`">{{ months[date.month] }} {{ date.year }}</p>
                    <div class="flex flex-row gap-3 mr-4 content-center" v-if="settings.arrows">
                        <div class="inline-flex gap-1 self-center">
                            <IconBase class="w-3 h-3">
                                <AnglesLeft class="cursor-pointer fill-slate-400 text-sm" @click="changeYear(-1)" @blur="removeFocus" />
                            </IconBase>
                            <IconBase class="w-3 h-3">
                            <ChevronLeft class="cursor-pointer fill-slate-600 text-sm" @click="changeMonth(-1)" @blur="removeFocus" />
                            </IconBase>
                        </div>
                        <div class="inline-flex gap-1 self-center">
                            <IconBase class="w-3 h-3">
                                <ChevronRight class="cursor-pointer fill-slate-600 text-sm" @click="changeMonth(1)" @blur="removeFocus" />
                            </IconBase>
                            <IconBase class="w-3 h-3">
                                <AnglesRight class="cursor-pointer fill-slate-400" @click="changeYear(1)" @blur="removeFocus" />
                            </IconBase>
                        </div>
                    </div>
                </div>

                <hr :class="`mt-3 m-2 ${settings.dark ? 'border-slate-500' : ''}`" />

                <div class="grid grid-cols-7 text-center mt-2" v-if="settings.names">
                    <span v-for="(item, index) in ['M', 'T', 'W', 'T', 'F', 'S', 'S']" :key="index" v-text="item" :class="`text-xs font-bold ${settings.dark ? 'text-slate-300' : 'text-black'}`" />
                </div>

                <div class="grid grid-cols-7 text-center mt-2 gap-y-0.5">
                <span :key="index"
                      @click="select(date)"
                      v-text="date.getDate()"
                      v-for="(date, index) in dates()"
                      :class="`text-xs font-light ${isDate(date) ? 'bg-cli-primary text-white shadow-md' : (isToday(date) ? ('border border-cli-primary ' + (settings.dark ? 'text-slate-300' : 'text-slate-500')) : (isInMonth(date) ? (settings.dark ? 'text-slate-300' : 'text-slate-500') : settings.dark ? 'text-slate-500' : 'text-slate-300') + ' border border-transparent')} p-0.5 w-1/2 rounded-md justify-self-center cursor-pointer`" />
                </div>

                <div class="mt-1.5 -mb-2 m-4 text-xs" v-if="settings.buttons.reset || settings.buttons.today || settings.buttons.nextWeek">
                    <div class="flex justify-between content-center">
                        <div class="flex gap-3">
                            <span v-if="settings.buttons.today" :class="`text-[9px] uppercase cursor-pointer ${(settings.dark ? 'text-slate-400 hover:text-slate-500' : 'text-cli-primary hover:text-cli-primary-dark')}`" @click="changeToday" v-text="'Today'" />
                            <span v-if="settings.buttons.nextWeek" :class="`text-[9px] uppercase cursor-pointer ${(settings.dark ? 'text-slate-400 hover:text-slate-500' : 'text-cli-primary hover:text-cli-primary-dark')}`" @click="changeNextWeek" v-text="'Next week'" />
                        </div>
                        <span v-if="settings.buttons.reset" :class="`text-[9px] uppercase cursor-pointer ${value && value.length > 0 ? 'text-red-500' : settings.dark ? 'text-gray-400' : 'text-gray-300'}`" @click="value && value.length > 0 ? clear() : undefined" v-text="'Clear'" />
                    </div>
                </div>

            </div>
        </div>
    </FormKit>
</template>

<script>
import {debounce} from "lodash";
import moment from "moment";
import ChevronLeft from "@/components/icons/wflwr/IconChevronLeft.vue";
import ChevronRight from "@/components/icons/wflwr/IconChevronRight.vue";
import AnglesRight from "@/components/icons/wflwr/IconAnglesRight.vue";
import AnglesLeft from "@/components/icons/wflwr/IconAnglesLeft.vue";
import IconBase from "@/components/icons/IconBase.vue";

export default {
    name: 'Date2',
    components: {
      IconBase,
      AnglesLeft,
      AnglesRight,
      ChevronRight,
      ChevronLeft,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            focused: false,
            value: this.context._value || null,
            settings: {
                dark: this.context.dark ?? false,
                arrows: this.context.arrows ?? true,
                names: this.context.names ?? true,
                buttons: {
                    today: this.context.today ?? true,
                    nextWeek: this.context.nextWeek ?? true,
                    reset: this.context.reset ?? true,
                },
            },
            date: {
                day: new Date().getDate(),
                month: new Date().getMonth(),
                year: new Date().getFullYear(),
                current: this.context.modelValue || null,
                today: new Date(),
            },
            modals: {
                year: false,
                month: false,
            },
            months: [
                "January", "February", "March", "April",
                "May", "June", "July", "August",
                "September", "October", "November", "December",
            ],
        }
    },
    methods: {
        select(date) {
            if (date === null) {
                this.date.current = null
                this.value = null
                this.context.node.input('')
                return
            }
            this.date.current = date
            const formatted = moment(date).format(this.context.format || "DD.MM.YYYY")
            this.value = formatted
            this.context.node.input(formatted)
        },
        changeDate(date) {
            this.date.day = date.getDate()
            this.date.month = date.getMonth()
            this.date.year = date.getFullYear()
            return new Date(this.date.year, this.date.month, this.date.day)
        },
        currentMaxDays() {
            const date = new Date(this.date.year, this.date.month, 0)
            return date.getDate()
        },
        changeToday() {
            this.changeDate(new Date())
            this.select(new Date())
        },
        changeNextWeek() {
            const date = this.date.current || new Date()
            date.setDate(date.getDate() + 7)
            this.changeDate(date)
            this.select(date)
        },
        clear() {
            this.select(null)
        },
        isInMonth(date) {
            return date.getMonth() === this.date.month
        },
        changeMonth(value) {
            const date = new Date()
            date.setFullYear(this.date.year,this.date.month + value, this.date.day)
            return this.changeDate(date)
        },
        changeYear(value) {
            const date = new Date()
            date.setFullYear(this.date.year + value, this.date.month, this.date.day)
            return this.changeDate(date)
        },
        isDate(date) {
            const selected = this.date.current
            if (!selected) return false
            return date.getDate() === selected.getDate() &&
                date.getMonth() === selected.getMonth() &&
                date.getFullYear() === selected.getFullYear()
        },
        isToday(date) {
            const today = this.date.today
            return date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear()
        },
        dates() {
            const array = []
            for (let day = -2; day < this.currentMaxDays(); day++) {
                const date = new Date()
                date.setFullYear(this.date.year, this.date.month, day)
                array.push(date)
            }
            return array
        },
        removeFocus: debounce((function (event) {
            if (event.relatedTarget === null) {
                this.focused = false
            }
        }), 100),
    },
}
</script>

<style scoped>
.disable-selection {
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.disable-outline * {
    outline: none;
}
</style>