/* eslint-disable no-underscore-dangle */
import { manage } from './Client'
import Model from '../models/Model'

/**
 * Remove "attributes." from errors object
 * @param promise
 * @returns {Q.Promise<never> | Promise<postcss.Result> | Promise<void> | Promise<any>}
 */
function renameValidationKeys(promise) {
  return promise.catch((error) => {
    if ('response' in error && error.response.status === 422) {
      const errors = {}
      Object.entries(error.response.data.errors).forEach(([key, value]) => {
        errors[key.replace('attributes.', '')] = value
      })
      // eslint-disable-next-line no-param-reassign
      error.response.data.errors = errors
    }

    return Promise.reject(error)
  })
}

export default class ApiResource {
  /**
   * Create a new resource e.g. const events = new ApiResource('events')
   * or if you have nested resources: const eventArticles = new ApiResource('events/123/articles')
   * @param {string} resource
   * @param {Model} model
   * @param {object} options
   */
  constructor(resource, model = Model, options = {}, permissions = {}) {
    this.resource = resource
    this.model = model
    this.options = options
    this.permissions = permissions
  }

  can(route) {
    const permissions = this.permissions[route]
    if (permissions) return gate.hasAll(this.permissions[route])
    return true
  }

  _options(options) {
    return {
      ...this.options,
      ...options
    }
  }

  /**
   * List/index action
   * @param params
   * @returns {*}
   */
  async list(params) {
    const { data: { data, included, ...rest } } = await manage.get(this.resource, { ...this.options, params })
    return {
      data: this.model.collection(data, included),
      ...rest
    }
  }

  canList() {
    return this.can('list')
  }

  async exportList(type, paramsHandover) {
    const params = {
      ...this.options,
      responseType: 'blob',
      ...paramsHandover
    }
    if (!params.params) {
      params.params = {}
    }
    params.params.export = type
    return manage.get(this.resource, params)
  }

  /**
   * Show action
   * @param id
   * @param options
   * @returns {*}
   */
  async show(id, options) {
    const { data: { data, included } } = await manage.get(`${this.resource}/${id}`, this._options(options))
    return this.model.create(data, included, this)
  }

  canShow() {
    return this.can('show')
  }

  /**
   * Create action
   * @param {*} payload
   * @param {Object} options
   * @param {Boolean} options.formData
   * @returns {*}
   */
  async store(payload, { formData = false, ...options } = {}) {
    const { data: { data, included } } = await renameValidationKeys(
        manage.post(
        this.resource,
        formData ? /* serialize(payload, { indices: true, booleansAsIntegers: true }) */ null : payload,
        this._options(options)
      )
    )
    return this.model.create(data, included, this)
  }

  canCreate() {
    return this.can('create')
  }

  /**
   * Update action
   * @param {Number|String} id
   * @param {*} payload
   * @param {Object} options
   * @param {Boolean} options.formData
   * @returns {*}
   */
  async update(id, payload, { refresh = false, formData = false, ...options } = {}) {
    // to support multiform data we need to send it as POST
    // for laravel we can add `_method` to payload, so laravel will handle it as PUT request
    const { data: { data, included } } = await renameValidationKeys(
      formData
        ? manage.post(
          `${this.resource}/${id}`,
          /* serialize({ ...payload, _method: 'put' }, { indices: true, booleansAsIntegers: true }) */ null,
          this._options(options)
        )
        : manage.put(`${this.resource}/${id}`, payload, this._options(options))
    )

    if( refresh ){
      if( data ) {
        return this.model.create(data, included, this)
      }else{
        this.model.show(id, options)
      }
    }
  }

  canUpdate() {
    return this.can('update')
  }

  /**
   * Delete action
   * @param id
   * @param options
   * @returns {*}
   */
  async remove(id, options) {
    const { data } = await manage.delete(`${this.resource}/${id}`, this._options(options))
    return data
  }

  canRemove() {
    return this.can('remove')
  }

  canExport() {
    return this.can('export')
  }
}
