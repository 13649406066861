<template>
  <div class="w-full flex flex-col justify-between mb-4" v-if="$gate.hasAll('wflwr.manage.settings.extended.raceTypeFilter')">
    <label for="typeFilter" class="block mb-2 text-sm font-medium text-gray-900">Type Filter</label>
    <select id="typeFilter" :value="race.state().raceTypeFilter ? race.state().raceTypeFilter : 'all'" :class="wflwrFormkitTheme.global.input" @change="$emit('raceTypeFilterChanged', $event)">
      <option class="uppercase h-8" v-for="type of [['all','all'], ['flagship','flagship'], ['app','app'], ['individual','individual'], ['organized','organized']]" :value="type[0]">{{type[1]}}</option>
    </select>
    <span class="text-xs text-gray-500 ml-1 mt-1">Limits top-bar information to given type</span>
  </div>
  <div class="w-full flex flex-col justify-between mb-4" v-if="$gate.hasAll('wflwr.manage.settings.extended.raceUpdateInterval')">
    <label for="updateInterval" class="block mb-2 text-sm font-medium text-gray-900">Update Interval</label>
    <select id="updateInterval" :value="race.state().updateInterval" :class="wflwrFormkitTheme.global.input" @change="$emit('intervalChanged', $event)">
      <option class="h-8" v-for="seconds of [300,60,30,15,10,5,1]" :value="seconds * 1000">{{seconds}}s</option>
    </select>
    <span class="text-xs text-gray-500 ml-1 mt-1">Top-bar information update interval</span>
  </div>
</template>
<script>
import race from "@/store/modules/race";
import wflwrFormkitTheme from "@/assets/css/wflwr-formkit-theme";

export default {
  name: "ExtendedSettings",
  emits: [
    'intervalChanged',
    'raceTypeFilterChanged'
  ],
  computed: {
    race() {
      return race
    },
    wflwrFormkitTheme() {
      return wflwrFormkitTheme
    },
  },
}
</script>
<style scoped lang="scss">

</style>