import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
            component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                children: [
                    {
                        path: 'leaderdevices',
                        name: 'leaderdevices',
                        component: () => import('../applications/Leaderdevice/Leaderdevices.vue'),
                    },
                    {
                        path: 'leaderdevices/:id',
                        name: 'leaderdevice',
                        props: true,
                        redirect: { name: 'leaderdevice.detail' },
                        component: () => import('../applications/Leaderdevice/Leaderdevice.vue'),
                        children: [
                            {
                                path: 'detail',
                                name: 'leaderdevice.detail',
                                props: true,
                                component: () => import('../applications/Leaderdevice/Leaderdevice/Detail.vue'),
                            },
                        ]
                    },
                ],
            }
        ]
    },
    {
        path: '/leaderdevices/maps',
        component: () => import('../layout/default/Absolute.vue'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                name: 'leaderdevices.maps',
                component: () => import('../applications/Leaderdevice/LeaderdevicesMaps.vue'),
            }
        ]

    }
]
