import {isLoggedIn} from "./guards";

export default [
  {
    path: '/',
    component: () => import('../layout/default/Default'),
    beforeEnter: isLoggedIn,
    children: [
      {
        path: '',
        name: 'teams.parent',
        redirect: {name: 'teams'},
        component: () => import('../layout/default/Absolute.vue'),
        props: {
          subNav: [
            {to: {name: "teams"}, name: "Teams", permission: "wflwr.manage.route.teams"},
            {to: {name: "team.stories"}, name: "Team Stories", permission: "wflwr.manage.route.team.stories"},
          ],
        },
        children: [
          {
            path: 'teams',
            name: 'teams',
            component: () => import('../applications/Team/Teams.vue'),
          },
          {
            path: 'teams/stories',
            name: 'team.stories',
            component: () => import('../applications/Team/TeamStories.vue'),
          },
        ],
      },
      {
        path: 'teams/:id',
        component: () => import('../layout/default/Absolute.vue'),
        beforeEnter: isLoggedIn,
        props: {
          refreshKeys: [
            'id',
          ],
          subNavHasRefresh: true,
          subNavInfo: "Team",
          subNav: [
            {to: {name: "teams.detail"}, name: "Detail"},
            {to: {name: "teams.users"}, name: "Users"},
            {to: {name: "teams.stickers"}, name: "Stickers"},
            {to: {name: "teams.comments"}, name: "Comments"},
            {to: {name: "teams.captain"}, name: "Captain"},
          ],
        },
        children: [
          {
            path: '',
            name: 'teams.team',
            redirect: { name: 'teams.detail' },
            props: true,
            component: () => import('../applications/Team/Team.vue'),
            children: [
              {
                path: 'detail',
                name: 'teams.detail',
                props: true,
                component: () => import('../applications/Team/Team/TeamDetail.vue'),
              },
              {
                path: 'users',
                name: 'teams.users',
                props: true,
                component: () => import('../applications/Team/Team/TeamUsers.vue'),
              },
              {
                path: 'comments',
                name: 'teams.comments',
                props: true,
                component: () => import('../applications/Team/Team/TeamComments.vue'),
              },
              {
                path: 'stickers',
                name: 'teams.stickers',
                props: true,
                component: () => import('../applications/Team/Team/TeamAudioStickers.vue'),
              },
              {
                path: 'captain',
                name: 'teams.captain',
                props: true,
                component: () => import('../applications/Team/Team/TeamCaptain.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
]
