import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
        component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                props: {
                    subNav: [
                        {to: {name: "events"}, name: "Events", permission: ['wflwr.models.event.list','wflwr.manage.route.events']},
                    ]
                },
                children: [
                    {
                        path: 'events',
                        name: 'events',
                        props: false,
                        component: () => import('../applications/Event/Events.vue'),
                    },
                ],
            }
        ]
    }
]