<template>
  <div class="w-full flex flex-col justify-between mb-4" v-if="$gate.hasAll('wflwr.manage.settings.general.eventYear')">
    <label for="eventYear" class="block mb-2 text-sm font-medium text-gray-900">RMS Event Year</label>
    <select id="eventYear" :value="race.state().eventYear" :class="wflwrFormkitTheme.global.input" @change="$emit('eventYearChanged', $event)">
      <option class="h-8" value="-1">all</option>
      <option class="h-8" v-for="eventYear of [...(race.state().eventYears ?? [])]" :value="eventYear">{{eventYear}} <template v-if="$store.state.race.defaultEventYear === eventYear">(Default)</template> </option>
    </select>
    <span class="text-xs text-gray-500 ml-1 mt-1">Updates local RMS Event Year</span>
  </div>
  <div class="w-full flex flex-col justify-between mb-4" v-if="$gate.hasAll('wflwr.manage.settings.general.fastLoadMode')">
    <label for="fastLoad" class="block mb-2 text-sm font-medium text-gray-900">Fast Load Mode</label>
    <select id="fastLoad" :value="layout.state().fastLoadMode" :class="wflwrFormkitTheme.global.input" @change="$emit('fastLoadModeChanged', $event)">
      <option class="h-16" v-for="item of [true,false]" :value="item">{{item}}</option>
    </select>
    <span class="text-xs text-gray-500 ml-1 mt-1">Custom pagination in data views (Runs, Participants,...)</span>
  </div>
</template>
<script>
import layout from "@/store/modules/layout";
import race from "@/store/modules/race";
import wflwrFormkitTheme from "@/assets/css/wflwr-formkit-theme";

export default {
  name: "GeneralSettings",
  emits: [
    'eventYearChanged',
    'fastLoadModeChanged'
  ],
  computed: {
    wflwrFormkitTheme() {
      return wflwrFormkitTheme
    },
    layout() {
      return layout
    },
    race() {
      return race
    }
  },
}
</script>
<style scoped lang="scss">

</style>