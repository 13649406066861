export function getFileDimensions(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
            const blob = new Blob([event.target.result], { type: file.type })
            createImageBitmap(blob).then(bitmap => {
                resolve({ width: bitmap.width, height: bitmap.height })
                bitmap.close()
            }).catch(error => reject(error))
        }
        reader.onerror = (error) => reject(error)
        reader.readAsArrayBuffer(file)
    })
}

export function downloadBlob(blob, fileName) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
        return;
    }
    const linkElement = document.createElement('a');
    linkElement.href = window.URL.createObjectURL(blob);
    linkElement.setAttribute('download', fileName);
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
}

export function downloadBlobType(type, source, fileName) {
    const types = {
        'gpx': 'text/gpx',
        'kml': 'text/kml',
        'json': 'application/json',
    };
    const blobType = types[type];
    if (!blobType) {
        return false;
    }
    const blob = new Blob(source, {
        type: blobType,
    });
    downloadBlob(blob, fileName);
    return true;
}

export function importFiles(accept, handler, error = () => {}) {
    const knownExtensions = {
        'gpx': 'text/gpx',
        'kml': 'text/kml',
        'json': 'application/json',
    };
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = accept;
    inputElement.onchange = (event) => {
        const files = event.target.files;
        if (files.length === 0) {
            error('No files selected');
            return;
        }
        for (const file of files) {
            let type = file.type;
            if (!type) {
                const names = file.name.split('.');
                const extension = names[names.length - 1];
                if (extension && knownExtensions[extension]) {
                    type = knownExtensions[extension];
                } else {
                    error('File type is unknown');
                    return;
                }
            }
            const reader = new FileReader();
            handler(file, type, reader);
            reader.onerror = () => error({
                message: reader.error.message,
            });
        }
    };
    inputElement.click();
}