import Resource from './../Resource'
import Donation from "@/models/donation/Donation";
import {manage} from "@/api/Client";

class DonationApi extends Resource {
    async stats(options) {
        const { data: { data } } = await manage.get(`donations/stats`, options)
        return data
    }

    async updateStatus(donationId, values) {
        return manage.put(`donations/${donationId}/editPaymentStatus`, values)
    }

    async showStatus(id, options) {
        const { data: data } = await manage.get(`donations/${id}/status`, this._options(options))
        return data
    }

    async calculateRefund(id, options) {
        const { data: data } = await manage.get(`donations/${id}/calculateRefund`, this._options(options))
        return data
    }
}

export default new DonationApi('donations', Donation)
