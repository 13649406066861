import axios from 'axios'
import router from './../router'
import i18n from '@/i18n'
import store, { SET_USER } from '@/store'
import layout from "@/store/modules/layout";
import race from "@/store/modules/race";

function apiFactory(baseURL) {
  const instance = axios.create({
    baseURL,
  })

  instance.interceptors.request.use((config) => {
    config.headers['Accept-Language'] = config.headers['Accept-Language'] || i18n.locale
    if(layout.state().fastLoadMode)
      config.headers['X-Simple-Pagination'] = String(layout.state().fastLoadMode)
    if(race.state().eventYear)
      config.headers['X-Event-Year'] = race.state().eventYear

    // Replace url parameters like :eventId in url ( /events/:eventId )
    if (config.url && config.params && config.params.urlParams) {
      Object.entries(config.params.urlParams)
        .forEach(([
          search,
          replacement
        ]) => {
          config.url = config.url.replace(`:${search}`, encodeURIComponent(replacement))
        })
      delete config.params.urlParams
    }

    return config
  })

  instance.interceptors.response.use((response) => response, (error) => {
    // ignore requests to "users/me" since they are used to check
    // if logged in and would cause an infinite loop
    if (
      error.config.url.indexOf('users/me') < 0
      && error.response.status === 401
      && error.config.url.indexOf('login') < 0
    ) {
      store.commit('auth/logout')
      router.push({ name: 'login', query: { redirect: router.currentRoute.fullPath } })
      return Promise.resolve()
    }

    if( error.response && error.response.data && error.response.data.message ){
      error.serverMessage = error.response.data.message;
    }

    if( error.response && error.response.data && error.response.data.errors && error.response.data.errors.permissions ){
      error.permissions = error.response.data.errors.permissions;
    }

    return Promise.reject(error)
  })

  return instance
}

export const external = apiFactory('/api/external/v1')
export const manage = apiFactory('/api/manage/v1')
export const auth = apiFactory('/api/auth/v1')