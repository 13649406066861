import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
        component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: 'custom-reports',
                name: 'custom-reports.parent',
                redirect: {
                    name: 'custom-reports.all',
                },
                component: () => import('../layout/default/Absolute.vue'),
                props: {
                    subNav: [
                        {to: {name: "custom-reports.all"}, name: "All reports", permission: ["wflwr.manage.route.custom-reports.all"]},
                        {to: {name: "custom-reports.country"}, name: "Country reports", permission: ["wflwr.manage.route.custom-reports.country"]},
                        {to: {name: "custom-reports.international"}, name: "International reports", permission: ["wflwr.manage.route.custom-reports.international"]},
                    ]
                },
                children: [
                    {
                        path: 'all',
                        name: 'custom-reports.all',
                        component: () => import('../applications/CustomReport/CustomReports.vue'),
                    },
                    {
                        path: 'country',
                        name: 'custom-reports.country',
                        component: () => import('../applications/CustomReport/CountryCustomReports.vue'),
                    },
                    {
                        path: 'international',
                        name: 'custom-reports.international',
                        component: () => import('../applications/CustomReport/InternationalCustomReports.vue'),
                    },
                ],
            }
        ]
    }
]