<template>
    <multiselect v-model="selectedLabels" :disabled="isLoading" :loading="isLoading" placeholder="Search a label" label="name" :optionHeight=10 :hideSelected="true" track-by="id" :options="availableLabels" :multiple="true" :show-labels="false" class="labelSelector">
      <template #tag="props">
        <span class="relative text-s inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold text-white rounded-full bg-blue-600 mr-1 mb-1" :style="'padding: 4px 26px 4px 10px;background-color:'+ props.option.color" >
        <span>
          {{ props.option.name }}
        </span>
          <i tabindex="1" @click="props.remove(props.option)" class="multiselect__tag-icon fill-white"></i>
        </span>
      </template>
      <template #option="props">
        <span class="leading-4">
          <span class="top-0 mr-1 w-4 h-4 inline-block relative bg-blue-600" :style="'border-radius:0.25rem;background-color:'+ props.option.color"></span>
          <span class="align-top ml-1">{{ props.option.name }}</span>
        </span>
      </template>
      <template #afterList v-if="!(disableManage || context?.disableManage)">
        <div class="mt-1 pt-1 border-t-2 h-11 hover:bg-gray-100 hover:text-black hover:cursor-pointer sticky bottom-0 bg-white" @click="redirect">
          <span class="pl-4 leading-4 align-middle">Manage Labels</span>
        </div>
      </template>
    </multiselect>
</template>

<script>
import LabelApi from "../../api/label/label"
import Multiselect from 'vue-multiselect'
import Run from "@/models/tracking/Run";
export default {
  name: "LabelSelector",
  components:{
    Multiselect
  },
  data(){
    return{
      availableLabels: [],
      isLoading : true,
      selectedLabels:[]
    }
  },
  props: {
    run: {
      type: [Run, null],
      required: false
    },
    disableManage: {
      type: [Boolean, false],
      required: false
    },
    context:Object,
  },
  async created() {
    this.availableLabels = (await LabelApi.listAll()).map((x) => {
      return {id: x.id, name: x.attributes.name, color: x.attributes.color}
    })
    this.isLoading = false
    if(this.run){
      this.selectedLabels = this.run.attributes.labels.map(x=>{return {id:x.id,name:x.attributes.name,color:x.attributes.color}})
    }
    this.$watch('selectedLabels', this.labelsChanged);
  },
  updated() {
    if(this.context?.node)
      this.context.node.input(this.selectedLabels.map(x=>x.id))
  },
  methods:{
     submitLabel:async function (context) {
       this.isLoading = true
       await this.run.update({"labels": this.selectedLabels.map(x => x.id)})
       this.isLoading = false
     },
    labelsChanged(newLabels, oldLabels) {
      if (newLabels!==oldLabels) {
        if(this.run)
          this.submitLabel(this)
        else{
          this.context.node.input(newLabels.map(x=>x.id))
        }
      }
    },
    redirect(){
       this.$router.push({ name: 'labels'});
    }
  },
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.labelSelector :deep(.multiselect__tag-icon:after){
  content: "×";
  font-size: 14px;
  color: white;
}

.labelSelector :deep(.multiselect__option--highlight){
  background: rgb(243 244 246);
  color: black;
}
.labelSelector :deep(.multiselect__content-wrapper){
  position: relative;
  width: initial;
}
</style>