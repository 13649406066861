import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
            component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                props: {
                    disableKeyRefresh: true,
                },
                children: [
                    {
                        path: 'race/infos',
                        name: 'race.infos',
                        component: () => import('../applications/RaceInfo/RaceInfo.vue'),
                        redirect: { name: 'race.infos.global' },
                        children: [
                            {
                                path: 'global',
                                name: 'race.infos.global',
                                props: true,
                                component: () => import('../applications/RaceInfo/RaceInfo/global.vue'),
                            },
                            {
                                path: 'racetype/:category',
                                name: 'race.infos.racetype',
                                props: true,
                                component: () => import('../applications/RaceInfo/RaceInfo/racetype.vue'),
                            },
                            {
                                path: 'eventtype/:category',
                                name: 'race.infos.eventtype',
                                props: true,
                                component: () => import('../applications/RaceInfo/RaceInfo/eventtype.vue'),
                            },
                            {
                                path: 'events/:eventId',
                                name: 'race.infos.events',
                                component: () => import('../applications/RaceInfo/RaceInfo/event.vue'),
                                props: true,
                            },
                            {
                                path: 'countries/:countryIso2',
                                name: 'race.infos.countries',
                                component: () => import('../applications/RaceInfo/RaceInfo/country.vue'),
                                props: true,
                            },
                        ]
                    }
                ],
            }
        ]
    }
]
