

<template>
  <div class="w-full flex flex-col justify-between mb-4" v-if="$gate.hasAll('wflwr.manage.settings.localization.locale')">
    <label for="updateInterval" class="block mb-2 text-sm font-medium text-gray-900">Locale Format</label>
    <select id="updateInterval" :value="layout.state().numberLocaleFormat" :class="wflwrFormkitTheme.global.input" @change="$emit('numberLocaleFormatChanged', $event)">
      <option class="h-8" v-for="item of ['de-DE','en-US']" :value="item">{{ item }}</option>
    </select>
  </div>
  <div class="w-full flex flex-col justify-between mb-4" v-if="$gate.hasAll('wflwr.manage.settings.localization.locationFormat')">
    <label for="updateInterval" class="block mb-2 text-sm font-medium text-gray-900">Location Format</label>
    <select id="updateInterval" :value="layout.state().locationFormat" :class="wflwrFormkitTheme.global.input" @change="$emit('locationFormatChanged', $event)">
      <option class="h-8" v-for="item of [{label: 'IOC', value: 'ioc'},{label: 'ISO2', value: 'iso2'},{label: 'Full', value: 'name'}]" :value="item.value">{{ item.label }}</option>
    </select>
  </div>
</template>
<script>
import layout from "@/store/modules/layout";
import wflwrFormkitTheme from "@/assets/css/wflwr-formkit-theme";

export default {
  name: "LocalizationSettings",
  emits: [
    'numberLocaleFormatChanged',
    'locationFormatChanged'
  ],
  computed: {
    layout() {
      return layout
    },
    wflwrFormkitTheme() {
      return wflwrFormkitTheme
    },
  },
}
</script>
<style scoped lang="scss">

</style>