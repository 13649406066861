import Resource from './../Resource'
import Registration from '../../models/participant/Registration'
import {manage} from "../Client";

class RegistrationApi extends Resource {

    async updateStatus(id, content, options) {
        const { data: data } = await manage.put(`registrations/${id}/updateStatus`, content, this._options(options))
        return data
    }

    async showStatus(id, options) {
        const { data: data } = await manage.get(`registrations/${id}/status`, this._options(options))
        return data
    }
    async calculateRefund(id, options) {
        const { data: data } = await manage.get(`registrations/${id}/calculateRefund`, this._options(options))
        return data
    }

    async resendConfirmationEmail(id, options) {
        return await manage.get(`${this.resource}/${id}/resendEmail`, this._options(options));
    }

}
export default new RegistrationApi('registrations', Registration, null, {
    create: ['models.registration.store'],
    update: ['models.registration.update'],
    remove: ['models.registration.delete']
})