import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
            component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                children: [
                    {
                        path: 'contacts',
                        name: 'contacts',
                        component: () => import('../applications/Settings/Contacts.vue'),
                    },
                    {
                        path: 'contacts/:id',
                        name: 'contact',
                        props: true,
                        redirect: { name: 'contact.detail' },
                        component: () => import('../applications/Settings/Contact.vue'),
                        children: [
                            {
                                path: 'detail',
                                name: 'contact.detail',
                                props: true,
                                component: () => import('../applications/Settings/Contact/Detail.vue'),
                            },
                            {
                                path: 'details',
                                name: 'contact.details',
                                props: true,
                                component: () => import('../applications/Settings/Contact/ContactDetails.vue'),
                            }
                        ]
                    },
                ],
            }
        ]
    }
]