<template>
  <RefundChargebackModal :id="id" :close="close" title="Registration" :hasRemark="true" :model="model"
                         :showCancelPayment="showCancelPayment" :on-status-change="onStatusChange">
    <template v-slot:end>
      <FormKit type="checkbox" label="Cancel Participants" name="cancelParticipants" :value="false" />
      <FormKit v-if="showDeleteVoucher" type="checkbox" label="Delete voucher" name="deleteVoucher" />
    </template>
  </RefundChargebackModal>
</template>

<script>
import RefundChargebackModal from '@/components/Modals/Layout/RefundChargeback/RefundChargebackModal.vue'
import RegistrationApi from '@/api/participant/registration'

export default {
  name: 'RefundChargebackRegistrationModal.vue',
  props: {
    id: Number,
    close: Function,
    showCancelPayment: Boolean,
    showDeleteVoucher: Boolean,
    onStatusChange: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  components:{
    RefundChargebackModal
  },computed:{
    model() {
      return RegistrationApi
    }
  }
}
</script>

<style scoped></style>