<template>
  <multiselect v-model="value" :placeholder="placeholder" :allow-empty="allowEmpty" track-by="value" label="label" :options="options" :show-labels="false" class="searchSelect" :disabled="disabled">
    <template #tag="props">
        <span class="item">
          {{ props.option.label }}
        </span>
    </template>
    <template slot="option" slot-scope="props">
      <div class="item"><span>{{ props.option.label }}</span></div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: "SearchSelect",
  components:{
    Multiselect
  },
  data(){
    return{
      value:null,
      placeholder:"Select",
      selectFirstIfOnlyOption:false,
    }
  },
  props: {
    context:Object,
  },
  computed:{
    disabled: function (){
      return this.context.disabled
    },
    options: function (){
      return this.context.options
    },
    allowEmpty: function (){
      return this.context?.allowEmpty??true
    },
  },
  async created() {
    if(this.context.placeholder)
      this.placeholder = this.context.placeholder
    if(this.context.disabled)
      this.disabled = this.context.disabled
    if(this.context.selectFirstIfOnlyOption)
      this.selectFirstIfOnlyOption = this.context.selectFirstIfOnlyOption
    if(this.context._value != null){
      let temp = {value: this.context._value, label: this.options.find(x=>x.value ==this.context._value)?.label}
      if(temp.label){
        this.value = temp
        this.context.node.input(this.value.value)
      }
    }

    if(this.selectFirstIfOnlyOption && this.options.length===1){
      let temp = {value: this.options[0]?.value, label: this.options[0]?.label}
      if(temp.label)
        this.value = temp
    }

    this.$watch('value', this.labelsChanged);
    this.$watch('options', this.optionsChanged);
    this.$watch('context._value', this.contextChanged);
  },
  updated() {
    if(this.context?.node && this.value)
      this.context.node.input(this.value.value)
  },
  methods:{
    labelsChanged(newO, oldO) {
      if (newO!==oldO && newO!=null) {
        if(this.context?.node)
          this.context.node.input(newO.value)
      }

      if(newO==null) {
        if (!this.allowEmpty) {
          this.value = oldO
        } else {
          this.value = {value: null, label:null}
        }
      }
    },
    setFirstOption() {
      let temp = {value: this.options[0]?.value, label: this.options[0]?.label}
      if(temp.label)
        this.value = temp
    },
    optionsChanged(newO, oldO){
      if (newO!==oldO) {
        if(this.context._value){
          let temp = {value: this.context._value, label: this.options.find(x=>x.value ==this.context._value)?.label}

          if(temp.label) {
            this.value = temp
          } else if(this.selectFirstIfOnlyOption && newO.length===1) {
            this.setFirstOption()
          } else{
            this.value = {value: null, label:null}
          }
        }
        else if(this.selectFirstIfOnlyOption && newO.length===1){
          this.setFirstOption()
        }
      }
    },
    contextChanged(new0,old0){
      if(new0 != old0 && new0 == null)
          this.value = {value: null, label:null}
    }
  },
}

</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.searchSelect :deep(.item){
    width: 100%;
    overflow: scroll;
  }
.multiselect--disabled, .multiselect--disabled :deep(*){
  cursor: default;
  background-color: rgba(239, 239, 239, 0.3)!important;
  color: black!important;
  border-color: rgba(118, 118, 118, 0.3)!important;
}

.multiselect{
    height: 0px;
    width: 100%;
    overflow:visible;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
    max-width: 100%;
    position: relative;
  }
.searchSelect :deep(.multiselect__single){
  font-size: 1rem;
  line-height: 2.3rem;
  padding: unset;
  margin: unset;
  }

.searchSelect :deep(.multiselect__tags),.searchSelect :deep(.multiselect__option),.searchSelect :deep(.multiselect__element){
    height: auto;
    position: inherit;
  }
.searchSelect :deep(.multiselect__tags){
    padding: 0 0 0 1rem;
    border-radius: 0.375rem;
    height: 2.5rem;
    min-height: 2.5rem;
  }
.searchSelect :deep(.multiselect__placeholder){
    line-height: 2.3rem;
    padding: unset;
    margin: unset;
  }

.searchSelect :deep(.multiselect__select){
    height: 100%;
    display: none;
  }
.searchSelect :deep(.multiselect__option--selected.multiselect__option--highlight) {
  background: #ff6a6a!important;
  color: #fff;
}
.searchSelect :deep(.multiselect__option--highlight){
    background: rgb(243 244 246);
    color: black;
  }
.searchSelect :deep(.multiselect__option){
    padding: 10px 0 0px 5px;
    font-size: 1rem;
  }
.searchSelect :deep(.multiselect__input){
    font-size: 1rem;
    line-height: 2.3rem;
    padding: unset;
    margin: unset;
  }
.searchSelect :deep(.multiselect__content-wrapper){
    position: absolute;
    width: 100%;
  }
</style>