/* eslint-disable no-underscore-dangle */

/**
 * Check if permission a is within context b
 * @param wanted
 * @param allowed
 * @returns {boolean}
 */
export function hasPermission(wanted, allowed) {
  const wantedParts = wanted.split('.')
  const allowedParts = allowed.split('.')

  if (!allowedParts.length) {
    return false
  }

  if (wantedParts.length > allowedParts.length && allowedParts[allowedParts.length-1] !== '*') {
    return false
  }

  return allowedParts.every((part, i) => {
    const wantedPart = wantedParts[i]
    return part === '*' || wantedPart === part
  })
}

export default class Gate {
  constructor({
    permissionPrefix = '',
    store
  }) {
    this.store = store
    this.permissionPrefix = permissionPrefix
  }

  hasOne(permissions, tenant, event, ignoreAppTenant = false) {
    const permissionsList = (typeof permissions === 'string' ? [permissions] : permissions)
    return permissionsList.some((permission) => this.allows(permission, tenant, event, ignoreAppTenant))
  }

  hasAll(permissions, tenant, event, ignoreAppTenant = false) {
    const permissionsList = (typeof permissions === 'string' ? [permissions] : permissions)
    return permissionsList.every((permission) => this.allows(permission, tenant, event, ignoreAppTenant))
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  /**
   * Check if permission can be granted
   * @param {string} permission
   * @param {any} tenant
   * @param {any} event
   */
  allows(permission, tenant = this.tenant(), event = this.event(), ignoreAppTenant = false) {
    let { tenants = {} } = this.permissions() || {}
    let permissions = [
      `${this.permissionPrefix}.manage.route.login`,
      `${this.permissionPrefix}.manage.route.dashboard`,
      `${this.permissionPrefix}.manage.route.manage-access-denied`
    ]

    if(ignoreAppTenant){
      let temp = { ...tenants };
      delete temp[1];
      tenants = temp
    }

    if (tenant && tenant.id in tenants) {
      permissions = (tenants[tenant.id] || {}).permissions || []

      if (event) {
        const eventPermissions = tenants[tenant.id].events[event.id]

        if (!eventPermissions) {
          return false
        }

        if (eventPermissions.maxPermissions) {
          permissions = permissions.filter(
            (tenantPermission) => (eventPermissions.maxPermissions || ['*']).some(
              (limit) => hasPermission(tenantPermission, limit)
            )
          )
        }
      }
    } else {
      Object.entries(tenants).forEach(([, { permissions: tenantPermissions }]) => {
        permissions = permissions.concat(tenantPermissions)
      })
    }

    if (event) {
      permissions = []
      Object.entries(tenants).forEach(([, { events = [], permissions: tenantPermissions }]) => {
        const eventPermissions = events[event.id] || {}

        if (!eventPermissions) {
          return
        }

        const grantedEventPermissions = tenantPermissions.filter(
          (tenantPermission) => (eventPermissions.maxPermissions || ['*']).some(
            (limit) => hasPermission(tenantPermission, limit)
          )
        )

        permissions = permissions.concat(grantedEventPermissions).sort()
      })
    }

    return permissions.some((grantedPermission) => hasPermission(this.permissionPrefix + permission, grantedPermission))
  }

  /**
   * Check if permission cannot be granted
   * @param args
   */
  denies(...args) {
    return !this.allows(...args)
  }

  user() {
    return this.store.state.auth.user || undefined
  }

  permissions() {
    return this.store.state.auth.permissions || undefined
  }

  tenant() {
    return undefined // current activated tenant
  }

  event() {
    return undefined // current activated event
  }
}
