export default {
    namespaced: true,
    state() {
        const leftOpen = localStorage.getItem('store.layout.sidebarLeftOpen')
        const rightOpen = localStorage.getItem('store.layout.sidebarRightOpen')
        const fastLoadMode = localStorage.getItem('store.layout.fastLoadMode')
        const leftBarOpenTabs = localStorage.getItem('store.layout.leftBarOpenTabs')
        const legacyTrackTableStyle = localStorage.getItem('store.layout.legacyTrackTableStyle')
        const numberLocaleFormat = localStorage.getItem('store.layout.numberLocaleFormat')
        const locationFormat = localStorage.getItem('store.layout.locationFormat')
        const trackModificationOpen = localStorage.getItem('store.layout.trackModificationOpen')

        return {
            sidebarLeftOpen: ( leftOpen === 'false' ? false : true ),
            sidebarRightOpen: ( rightOpen === 'false' ? false : true ),
            fastLoadMode: ( fastLoadMode === 'true' ? true : false ),
            leftBarOpenTabs: leftBarOpenTabs ? JSON.parse(leftBarOpenTabs) : null,
            legacyTrackTableStyle: ( legacyTrackTableStyle === 'true' ? true : false ),
            numberLocaleFormat: numberLocaleFormat ?? "en-US",
            locationFormat: locationFormat ?? "ioc",
            trackModificationOpen: ( trackModificationOpen === 'true' )
        }
    },
    mutations: {
        toggleSidebarLeft(state){
            state.sidebarLeftOpen = !state.sidebarLeftOpen
            localStorage.setItem('store.layout.sidebarLeftOpen', String(state.sidebarLeftOpen))
        },
        toggleSidebarRight(state, value){
            state.sidebarRightOpen = ( typeof value !== "undefined" ? value : !state.sidebarRightOpen )
            localStorage.setItem('store.layout.sidebarRightOpen', String(state.sidebarRightOpen))
        },
        setFastLoadMode(state, payload) {
            state.fastLoadMode = payload
            localStorage.setItem('store.layout.fastLoadMode', String(state.fastLoadMode))
        },
        setLeftBarOpenTabs(state, payload) {
            state.leftBarOpenTabs = payload
            localStorage.setItem('store.layout.leftBarOpenTabs', JSON.stringify(state.leftBarOpenTabs))
        },
        setLegacyTrackTableStyle(state, payload) {
            state.legacyTrackTableStyle = payload
            localStorage.setItem('store.layout.legacyTrackTableStyle', JSON.stringify(state.legacyTrackTableStyle))
        },
        setNumberLocaleFormat(state, payload) {
            state.numberLocaleFormat = payload ?? 'en-US'
            localStorage.setItem('store.layout.numberLocaleFormat', String(state.numberLocaleFormat))
        },
        setLocationFormat(state, payload) {
            state.locationFormat = payload
            localStorage.setItem('store.layout.locationFormat', String(state.locationFormat))
        },
        toggleTrackModificationOpen(state, value){
            state.trackModificationOpen = ( typeof value !== "undefined" ? value : !state.trackModificationOpen )
            localStorage.setItem('store.layout.trackModificationOpen', String(state.trackModificationOpen))
        },
    },
}