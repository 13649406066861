class ClickOutside {

    install(app) {
        app.directive('clickOutside', {
            beforeMount: (el, binding) => {
                const value = binding.value
                if (!(value instanceof Function)) {
                    console.error("[ClickOutside] Value must be a function")
                    return
                }
                el.clickOutsideEvent = event => {
                    const target = event.target
                    if (!(el === target || el.contains(target)) && !el.contains(target)) {
                        value(event);
                    }
                }
                setTimeout(() => document.addEventListener('click', el.clickOutsideEvent), 200)
            },
            beforeUnmount: (el) => document.removeEventListener('click', el.clickOutsideEvent),
        })
    }

}

const clickOutside = new ClickOutside()
export default clickOutside