import { getFileDimensions } from "@/helpers/FileHelper";

async function fileDimensionMax(node, width, height) {
    return await _fileDimension(node, width, height, false)
}

async function fileDimensionMin(node, width, height) {
    return await _fileDimension(node, width, height, true)
}

async function _fileDimension(node, paramWidth, paramHeight, isMin) {
    const compareFn = (a, b) => b && (isMin ? a >= parseInt(b) : a <= parseInt(b))
    for (const entry of node._value.filter(entry => entry.file)) {
        const { width, height } = await getFileDimensions(entry.file)
        if (!(compareFn(width, paramWidth) && compareFn(height, paramHeight))) {
            return false
        }
    }
    return true
}

export {
    fileDimensionMax,
    fileDimensionMin,
}