<template>
  <div class="mb-4" v-show="hasSlot">
    <div
      class="text-white justify-between bg-cli-primary px-2 py-2 rounded-t flex items-center cursor-pointer uppercase font font-semibold"
      :class="!expanded?'rounded-b':''"
      @click="toggle"
    >
      {{ title }}
      <IconBase class="w-3.5 mr-2 inline text-white">
        <IconMinus v-if="expanded" /><IconPlus v-else />
      </IconBase>
    </div>
    <div class="px-2 border-l-2 border-l-cli-primary pt-4 pb-1 shadow" v-show="expanded" ref="content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import IconPlus from "@/components/icons/wflwr/IconPlus.vue";
import IconMinus from "@/components/icons/wflwr/IconMinus.vue";
import IconBase from "@/components/icons/IconBase.vue";

export default {
  name: "Collapsible",
  components: { IconBase, IconMinus, IconPlus },
  props: {
    title: {
      type: [String, ""],
      required: true
    },
    open: {
      type: [Boolean, false],
      required: false
    },
  },
  data() {
    return {
      expanded: false,
      hasSlot: true
    };
  },
  computed: {

  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    checkHasSlot() {
      this.hasSlot = this.$refs.content.innerText !== ""
    }
  },
  mounted() {
    this.expanded = this.open
    this.checkHasSlot()
  },
};
</script>
<style scoped lang="scss"></style>