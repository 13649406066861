<template>
  <div
    class="relative z-10"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    ></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div
        class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      >
        <div
          class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start sm:flex-col">
              <div class="flex flex-row">
                <div
                  class="mx-auto flex h-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10 mr-4"
                >
                  <svg
                    class="h-6 w-6 text-cli-primary"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>
                </div>
                <h3
                  class="text-lg font-medium leading-6 text-gray-900 my-2 ml-4"
                  id="modal-title"
                >
                  RMS - Settings
                </h3>
              </div>
              <div class="border-gray-800 border-b-2 my-2 w-full"></div>
              <div class="mt-3 text-left w-full">
                <div class="mt-2 w-full">
                  <Collapsible title="General" :open="true">
                      <GeneralSettings
                      @eventYearChanged="eventYearChanged"
                      @fastLoadModeChanged="fastLoadModeChanged"
                    />
                  </Collapsible>
                  <Collapsible title="Localization - Statistics">
                    <LocalizationSettings
                      @locationFormatChanged="locationFormatChanged"
                      @numberLocaleFormatChanged="numberLocaleFormatChanged"
                    />
                  </Collapsible>
                  <Collapsible title="Extended">
                    <ExtendedSettings
                      @intervalChanged="intervalChanged"
                      @raceTypeFilterChanged="raceTypeFilterChanged"
                    />
                  </Collapsible>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              class="inline-flex w-full justify-center rounded-md border border-transparent bg-cli-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-cli-primary-dark focus:outline-none focus:ring-2 focus:ring-bg-cli-primary-dark focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              @click="done"
            >
              Apply
            </button>
            <button
              type="button"
              class="inline-flex w-full justify-center rounded-md border border-transparent bg-cli-secondary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-cli-secondary-dark focus:outline-none focus:ring-2 focus:ring-bg-cli-secondary-dark focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              @click="close"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import race from "@/store/modules/race";
import layout from "@/store/modules/layout";
import ExtendedSettings from "@/components/Modals/Settings/ExtendedSettings.vue";
import LocalizationSettings from "@/components/Modals/Settings/LocalizationSettings.vue";
import GeneralSettings from "@/components/Modals/Settings/GeneralSettings.vue";
import Collapsible from "@/components/Collapsible/Collapsible.vue";

export default {
  name: "ExtendedRMSOptionsModal.vue",
  computed: {
    layout() {
      return layout;
    },
    race() {
      return race;
    },
  },
  components: {
    Collapsible,
    GeneralSettings,
    LocalizationSettings,
    ExtendedSettings,
  },
  props: { close: Function },
  data() {
    return {
      interval: null,
      raceType: null,
      eventYear: null,
      fastLoadMode: null,
      numberLocaleFormat: null,
      locationFormat: null,
    };
  },
  methods: {
    async done() {
      if (this.interval)
        this.$store.commit("race/setUpdateInterval", this.interval);
      if (this.raceType)
        this.$store.commit("race/setRaceTypeFilter", this.raceType);
      if (this.eventYear)
        this.$store.commit("race/setEventYear", this.eventYear);
      if (this.fastLoadMode)
        this.$store.commit("layout/setFastLoadMode", this.fastLoadMode);
      if (this.numberLocaleFormat)
        this.$store.commit(
          "layout/setNumberLocaleFormat",
          this.numberLocaleFormat
        );
      if (this.locationFormat)
        this.$store.commit("layout/setLocationFormat", this.locationFormat);

      this.close();

      if (this.eventYear) await this.$router.replace({ query: null });
      window.location.reload();
    },
    intervalChanged(e) {
      this.interval = e.target.value;
    },
    raceTypeFilterChanged(e) {
      this.raceType = e.target.value;
    },
    async eventYearChanged(e) {
      this.eventYear = e.target.value;
    },
    async fastLoadModeChanged(e) {
      this.fastLoadMode = e.target.value;
    },
    async numberLocaleFormatChanged(e) {
      this.numberLocaleFormat = e.target.value;
    },
    async locationFormatChanged(e) {
      this.locationFormat = e.target.value;
    },
  },
};
</script>

<style scoped></style>
