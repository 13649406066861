import {isLoggedIn} from "./guards";

export default [
    {
                path: '/',
                component: () => import('../layout/default/Absolute.vue'),
                beforeEnter: isLoggedIn,
                children: [
                    {
                        path: 'busdriver',
                        name: 'busdriver',
                        component: () => import('../applications/Busdriver/Busdriver.vue'),
                    }
                ]

    }
]