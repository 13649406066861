import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
        component: () => import('../layout/blank/Blank'),
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import('../views/Login.vue')
            },
            {
                path: 'changePassword',
                name: 'changePassword',
                beforeEnter: isLoggedIn,
                component: () => import('../views/ChangePassword.vue')
            }
        ]
    },
    {
        path: '/logout',
        name: 'logout',
        redirect: { name: 'login', query: { logout: true } }
    }
]