import Resource from './../Resource'
import CustomReport from "../../models/customReport/CustomReport";
import {manage} from "@/api/Client";

class CustomReportApi extends Resource {

    async download(id, values, options) {
        const parameters = new URLSearchParams();
        for (const key in values) {
            parameters.set(key, values[key]);
        }
        return await manage.get(`customreports/${id}?${parameters.toString()}`, options)
    }

    async list(params = {}) {
        const promise = await manage.get(this.resource, {...this.options, params})
        const {data: {data, included, ...rest}} = await promise
        return {
            data: this.model.collection(data, included),
            ...rest
        }
    }

}

export default new CustomReportApi('customreports', CustomReport, null, {
    create: ['models.custom-report'],
    update: ['models.custom-report'],
    remove: ['models.custom-report']
})
