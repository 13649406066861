import {isLoggedIn} from "./guards";

export default [
  {
    path: '/',
    component: () => import('../layout/default/Default'),
    beforeEnter: isLoggedIn,
    children: [
      {
        path: '',
        name: 'participants.parent',
        redirect: {name: 'participants'},
        component: () => import('../layout/default/Absolute.vue'),
        props: {
          refreshKeys: [
            'id',
          ],
          subNav: [
            {to: {name: "participants"}, name: "Participants", permission: ["wflwr.models.participant.list", "wflwr.manage.route.participants.participants"]},
            {to: {name: "startnumbers"}, name: "Startnumbers", permission: "wflwr.models.startnumber.list"},
            {to: {name: "registrations"}, name: "Payments", permission: "wflwr.manage.route.participants.registrations"},
            {to: {name: "preRegistrations"}, name: "Pre-Registrations", permission: ["wflwr.models.preRegistration.list", "wflwr.manage.route.preRegistrations"]},
          ],
        },
        children: [
          {
            path: 'participants',
            name: 'participants',
            component: () => import('../applications/Participant/Participants.vue'),
          },
          {
            path: 'participants/create-link',
            name: 'participant.create-link',
            component: () => import('../applications/Participant/ParticipantCreateLink.vue'),
          },
          {
            path: 'participants/create',
            name: 'participant.create',
            component: () => import('../applications/Participant/ParticipantCreate.vue'),
          },
          {
            path: 'startnumbers',
            name: 'startnumbers',
            component: () => import('../applications/Participant/Startnumbers.vue'),
          },
          {
            path: 'registrations',
            name: 'registrations',
            component: () => import('../applications/Participant/Registrations.vue'),
          },
          {
            path: 'pre-registrations',
            name: 'preRegistrations',
            component: () => import('../applications/Participant/PreRegistrations.vue'),
          },
        ],
      },
      {
        path: 'participants/:id',
        component: () => import('../layout/default/Absolute.vue'),
        beforeEnter: isLoggedIn,
        props: {
          refreshKeys: [
            'id',
          ],
          subNavInfo: "Participant",
          subNavHasRefresh: true,
          subNav: [
            {to: {name: "participant.detail"}, name: "Detail"},
            {to: {name: "participant.startnumbers"}, name: "Startnumbers"},
            {
              to: {name: "participant.detections"},
              name: "Detections",
              permission: "wflwr.models.participant.detection.show",
            },
            {to: {name: "participant.articles"}, name: "Articles"},
            {to: {name: "participant.user"}, name: "User"},
          ],
        },
        children: [
          {
            path: '',
            name: 'participant',
            props: true,
            redirect: { name: 'participant.detail' },
            component: () => import('../applications/Participant/Participant.vue'),
            children: [
              {
                path: 'detail',
                name: 'participant.detail',
                props: true,
                component: () => import('../applications/Participant/Participant/Detail.vue'),
              },
              {
                path: 'startnumbers',
                name: 'participant.startnumbers',
                props: true,
                component: () => import('../applications/Participant/Participant/Startnumbers.vue'),
              },
              {
                path: 'detections',
                name: 'participant.detections',
                props: true,
                component: () => import('../applications/Participant/Participant/Detections.vue'),
              },
              {
                path: 'articles',
                name: 'participant.articles',
                props: true,
                component: () => import('../applications/Participant/Participant/Articles.vue'),
              },
              {
                path: 'user',
                name: 'participant.user',
                props: true,
                component: () => import('../applications/Participant/Participant/User.vue'),
              },
            ],
          },
        ],
      }]},
]
