import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
            component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: 'finalResults/',
                name: 'finalResults.parent',
                redirect: {name: 'finalResults.linkUser'},
                component: () => import('../layout/default/Absolute.vue'),
                props: {
                    subNav: [
                        {to: {name: "finalResults.linkUser"}, name: "Link User Result"},
                        {to: {name: "finalResults.upload"}, name: "Result Upload"},
                        {to: {name: "finalResults.complaints"}, name: "Complaints"},
                    ],
                },
                children: [
                    {
                        path: 'upload',
                        name: 'finalResults.upload',
                        component: () => import('../applications/FinalResult/ResultUploadList.vue'),
                    },
                    {
                        path: 'upload/diff/:versionA/:versionB?',
                        name: 'finalResults.upload.diff',
                        props: true,
                        component: () => import('../applications/FinalResult/ResultDiff.vue'),
                    },
                    {
                        path: 'link',
                        name: 'finalResults.linkUser',
                        props: false,
                        component: () => import('../applications/FinalResult/LinkUser/ResultSelect.vue'),
                    },
                    {
                        path: 'link/:resultId',
                        name: 'finalResults.linkUser.user',
                        props: true,
                        component: () => import('../applications/FinalResult/LinkUser/UserSelect.vue'),
                    },
                    {
                        path: 'link/:resultId/:userId',
                        name: 'finalResults.linkUser.confirm',
                        props: true,
                        component: () => import('../applications/FinalResult/LinkUser/Confirm.vue'),
                    },
                    {
                        path: 'complaints',
                        name: 'finalResults.complaints',
                        props: false,
                        component: () => import('../applications/FinalResult/Complaint/Complaints.vue'),
                    },
                    {
                        path: 'complaints/:id',
                        name: 'finalResults.complaints.detail',
                        props: true,
                        component: () => import('../applications/FinalResult/Complaint/Complaint.vue'),
                    },
                ],
            }
        ]
    }
]