import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
        component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                children: [
                    {
                        path: 'modifications',
                        name: 'modifications',
                        component: () => import('../applications/Modifications/Modifications.vue'),
                        redirect: { name: 'modifications.overview.overview' },
                        children: [
                            {
                                path: 'overview',
                                name: 'modifications.overview',
                                redirect: { name: 'modifications.overview.leaderboard-generator' },
                                component: () => import('../applications/Modifications/Overview.vue'),
                                children: [
                                    {
                                        path: 'overview',
                                        props: true,
                                        name: 'modifications.overview.overview',
                                        component: () => import('../applications/Modifications/Overview/Overview.vue'),
                                    },
                                    {
                                        path: 'leaderboard-generator',
                                        props: true,
                                        name: 'modifications.overview.leaderboard-generator',
                                        component: () => import('../applications/Modifications/Overview/LeaderboardGeneratorStatus.vue'),
                                    },
                                    {
                                        path: 'wscore-general',
                                        props: true,
                                        name: 'modifications.overview.wscore-general',
                                        component: () => import('../applications/Modifications/Overview/WscoreGeneral.vue'),
                                    },
                                    {
                                        path: 'wscore-logs',
                                        props: true,
                                        name: 'modifications.overview.wscore-logs',
                                        component: () => import('../applications/Modifications/Overview/WscoreLogs.vue'),
                                    },
                                    {
                                        path: 'wscore-lines',
                                        props: true,
                                        name: 'modifications.overview.wscore-lines',
                                        component: () => import('../applications/Modifications/Overview/WscoreLines.vue'),
                                    },
                                    {
                                        path: 'wscore-interfaces',
                                        props: true,
                                        name: 'modifications.overview.wscore-interfaces',
                                        component: () => import('../applications/Modifications/Overview/WscoreInterfaces.vue'),
                                    },
                                    {
                                        path: 'lifecycle-checks',
                                        props: true,
                                        name: 'modifications.overview.lifecycle',
                                        component: () => import('../applications/Modifications/Overview/Lifecycle.vue'),
                                    },
                                ]
                            },
                            {
                                path: 'events/:id',
                                name: 'modifications.event',
                                props: true,
                                redirect: (to) => ({name: 'modifications.event.overview', params: to.params }),
                                component: () => import('../applications/Modifications/Event.vue'),
                                children: [
                                    {
                                        path: 'overview',
                                        props: true,
                                        name: 'modifications.event.overview',
                                        component: () => import('../applications/Modifications/Event/Overview.vue'),
                                    },
                                    {
                                        path: 'event',
                                        name: 'modifications.event.event',
                                        component: () => import('../applications/Modifications/Event/Event.vue'),
                                    },
                                    {
                                        path: 'leaders',
                                        name: 'modifications.event.leaders',
                                        component: () => import('../applications/Modifications/Event/Leaders.vue'),
                                    },
                                    {
                                        path: 'obus',
                                        name: 'modifications.event.obus',
                                        component: () => import('../applications/Modifications/Event/Obus.vue'),
                                    },
                                    {
                                        path: 'leader-devices',
                                        name: 'modifications.event.leaderdevices',
                                        component: () => import('../applications/Modifications/Event/LeaderDevices.vue'),
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
]
