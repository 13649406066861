import axios from 'axios'
import {createI18n} from 'vue-i18n'

function getTranslations(groups) {
  return axios.get('translations', {
    baseURL: process.env.VUE_APP_API_URL || '/api/manage/v1',
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    },
    params: {
      groups
    }
  })
}

function loadLocaleMessages() {
  const locale = process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'
  const messages = {}

  messages[locale] = {}
  // Todo: load only needed groups, instead of the complete translation set
  getTranslations(null)
    .then((translations) => {
      messages[locale] = translations.data
    })

  return messages
}

const messages = {
  en: {
    message: {
      hello: 'hello world'
    }
  }
}

const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
})

export default i18n;