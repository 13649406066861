import Resource from '../Resource'
import Label from "../../models/label/Label";
import {manage} from "@/api/Client";

class LabelApi extends Resource {
    async listAll() {
        const { data: { data } } = await manage.get(`labels/all`)
        return data
    }
}

export default new LabelApi('labels', Label, null, {
    create: ['models.label.store'],
    update: ['models.label.update'],
    remove: ['models.label.delete']
})
