<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 128 128"
       :aria-labelledby="iconName"
       role="presentation"
  >
    <title v-if="title" :id="iconName" lang="en">{{title}}</title>
    <g :fill="iconColor" :class="iconClass">
      <slot/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    iconName: {
      type: String,
      default: 'box'
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    iconClass: {
      type: String,
      default: '',
    }
  }
}
</script>