import {createStore} from 'vuex'
import auth from './modules/auth'
import layout from "./modules/layout";
import router from "./modules/router";
import race from "./modules/race";
import global from "./modules/global"

export default createStore({
    modules: {
        auth,
        layout,
        router,
        race,
        global
    }
})
