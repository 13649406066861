import {isLoggedIn} from "./guards";

export default [
  {
    path: '/',
    component: () => import('../layout/default/Default'),
    beforeEnter: isLoggedIn,
    children: [
      {
        path: '',
        component: () => import('../layout/default/Absolute.vue'),
        props:{
          subNav: [
            {to: {name: "statistics.home"}, name: "Home"},
            {to: {name: "statistics.runs"}, name: "Runs and Trainings", permission:["wflwr.manage.route.statistics.runs","wflwr.models.runResult.list"]},
            {to: {name: "statistics.registrations.all"}, name: "Overall Registrations", permission:"wflwr.manage.route.statistics.registrations"},
            {to: {name: "statistics.registrations.flagship"}, name: "Flagship Registrations", permission:"wflwr.manage.route.statistics.registrations.flagship"},
            {to: {name: "statistics.registrations.app"}, name: "App Registrations", permission:"wflwr.manage.route.statistics.registrations.app"},
            {to: {name: "statistics.vouchers"}, name: "Vouchers", permission:"wflwr.manage.route.statistics.vouchers"},
            {to: {name: "statistics.donations"}, name: "Donations", permission:"wflwr.manage.route.statistics.donations"},
            {name: 'Extra', items:[{to: {name: "statistics.race"}, name: "Race", permission:"wflwr.manage.route.statistics.race"}]},
          ],
        },
        children: [
          {
            path: 'statistics',
            name:'statistics',
            component: () => import('../applications/Statistic/Statistic.vue'),
            children: [
              {
                path: 'runs',
                name: 'statistics.runs',
                component: () => import('../applications/Statistic/Pages/Runs.vue'),
              },
              {
                path: 'app-registrations',
                name: 'statistics.registrations.app',
                component: () => import('../applications/Statistic/Pages/AppRegistrations.vue'),
              },
              {
                path: 'flagship-registrations',
                name: 'statistics.registrations.flagship',
                component: () => import('../applications/Statistic/Pages/FlagshipRegistrations.vue'),
              },
              {
                path: 'all-registrations',
                name: 'statistics.registrations.all',
                component: () => import('../applications/Statistic/Pages/AllRegistrations.vue'),
              },
              {
                path: 'vouchers',
                name: 'statistics.vouchers',
                component: () => import('../applications/Statistic/Pages/Vouchers.vue'),
              },
              {
                path: 'home',
                name: 'statistics.home',
                component: () => import('../applications/Statistic/Pages/Home.vue'),
              },
              {
                path: 'donations',
                name: 'statistics.donations',
                component: () => import('../applications/Statistic/Pages/Donations.vue'),
              },
              {
                path: 'race',
                name: 'statistics.race',
                component: () => import('../applications/Statistic/Pages/Race.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
]