import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
            component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                children: [
                    {
                        path: 'communityRuns',
                        name: 'communityRuns',
                        component: () => import('../applications/CommunityRuns/CommunityRuns.vue'),
                    },
                    {
                        path: 'communityRuns/:id',
                        name: 'communityRuns.run',
                        props: true,
                        redirect: { name: 'communityRuns.run.detail' },
                        component: () => import('../applications/CommunityRuns/CommunityRun.vue'),
                        children: [
                            {
                                path: 'detail',
                                name: 'communityRuns.run.detail',
                                props: true,
                                component: () => import('../applications/CommunityRuns/CommunityRun/Detail.vue'),
                            },
                            {
                                path: 'users',
                                name: 'communityRuns.run.users',
                                props: true,
                                component: () => import('../applications/CommunityRuns/CommunityRun/Users.vue'),
                            },
                            {
                                path: 'comments',
                                name: 'communityRuns.run.comments',
                                props: true,
                                component: () => import('../applications/CommunityRuns/CommunityRun/Comments.vue'),
                            },
                        ]
                    },
                ],
            }
        ]
    }
]