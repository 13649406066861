import {isLoggedIn} from "./guards";

export default [
  {
    path: '/',
    component: () => import('../layout/default/Default'),
    beforeEnter: isLoggedIn,
    children: [
      {
        path: 'accounting',
        component: () => import('../layout/default/Absolute.vue'),
        props: {
          subNav: [
            {to: {name: "accounting.tenantSelect"}, name: "Tenants", permission: ['wflwr.accounting.tenant']},
            {to: {name: "accounting.income"}, name: "Income", permission:['wflwr.accounting.income.list']},
          ],
        },
        children: [
          {
            path: 'tenants',
            name: 'accounting.tenantSelect',
            props: false,
            component: () => import('../applications/Accounting/Tenant/TenantSelect.vue'),
          },
          {
            path: 'income',
            name: 'accounting.income',
            props: false,
            component: () => import('../applications/Accounting/Income/Income.vue'),
          },
        ],
      },
      {
        path: 'accounting/tenants/:tenantId',
        component: () => import('../layout/default/Absolute.vue'),
        props: {
          subNav: [
            {to: {name: "accounting.tenant.registrationsVoucherOrders"}, name: "Registrations & Voucher Orders", permission:['wflwr.accounting.tenant.registration.list', 'wflwr.accounting.tenant.voucherOrder.list']},
            {to: {name: "accounting.tenant.adyen.registrationsVoucherOrders"}, name: "Adyen - Registrations & Voucher Orders", permission:['wflwr.accounting.tenant.adyen.registration.list', 'wflwr.accounting.tenant.adyen.voucherOrder.list']},
            {to: {name: "accounting.tenant.adyen.donations"}, name: "Adyen - Donations", permission:['wflwr.accounting.tenant.adyen.donation.list']},
            {name: "More", items: [
              {to: {name: "accounting.tenant.voucherOrders"}, name: "Voucher Orders", permission:['wflwr.accounting.tenant.voucherOrder.list']},
              {to: {name: "accounting.tenant.registrations"}, name: "Registrations", permission:['wflwr.accounting.tenant.registration.list']},
              {to: {name: "accounting.tenant.adyen.voucherOrders"}, name: "Adyen - Voucher Orders", permission:['wflwr.accounting.tenant.adyen.voucherOrder.list']},
              {to: {name: "accounting.tenant.adyen.registrations"}, name: "Adyen - Registrations", permission:['wflwr.accounting.tenant.adyen.registration.list']},
              {to: {name: "accounting.tenant.donations"}, name: "Donations", permission:['wflwr.accounting.tenant.donation.list']},
            ]},
          ],
        },
        children: [
          {
            path: 'registrationsVoucherOrders',
            name: 'accounting.tenant.registrationsVoucherOrders',
            props: true,
            component: () => import('../applications/Accounting/Tenant/TenantAccountingRegistrationsVoucherOrders.vue'),
          },
          {
            path: 'voucherOrders',
            name: 'accounting.tenant.voucherOrders',
            props: true,
            component: () => import('../applications/Accounting/Tenant/TenantAccountingVoucherOrders.vue'),
          },
          {
            path: 'registrations',
            name: 'accounting.tenant.registrations',
            props: true,
            component: () => import('../applications/Accounting/Tenant/TenantAccountingRegistrations.vue'),
          },
          {
            path: 'adyen/registrationsVoucherOrders',
            name: 'accounting.tenant.adyen.registrationsVoucherOrders',
            props: true,
            component: () => import('../applications/Accounting/Adyen/AdyenTenantAccountingRegistrationsVoucherOrders.vue'),
          },
          {
            path: 'adyen/voucherOrders',
            name: 'accounting.tenant.adyen.voucherOrders',
            props: true,
            component: () => import('../applications/Accounting/Adyen/AdyenTenantAccountingVoucherOrders.vue'),
          },
          {
            path: 'adyen/registrations',
            name: 'accounting.tenant.adyen.registrations',
            props: true,
            component: () => import('../applications/Accounting/Adyen/AdyenTenantAccountingRegistrations.vue'),
          },
          {
            path: 'donations',
            name: 'accounting.tenant.donations',
            props: true,
            component: () => import('../applications/Accounting/Donation/TenantAccountingDonations.vue'),
          },
          {
            path: 'adyen/donations',
            name: 'accounting.tenant.adyen.donations',
            props: true,
            component: () => import('../applications/Accounting/Adyen/AdyenTenantAccountingDonations.vue'),
          },
        ],
      },
    ],
  },
]