<template>
  <Modal :title="`Edit participant #${id}`" :close="close">
    <template v-slot:icon>
      <IconPencil />
    </template>
    <template v-slot:content>
      <FormKit :value="value"  #default="{value}" v-if="value" type="form" id="cancelForm" :actions="false" @submit="save">
        <FormKit type="checkbox" :classes="defaultCheckbox" label="Cancel Participants" name="cancelParticipants" :value="false" />
      </FormKit>
    </template>
    <template v-slot:footer>
      <div class="flex flex-row gap-4">
        <button class="flex-1 rounded pt-2 pb-2 pr-4 pl-4 text-white bg-cli-primary disabled:bg-gray-200" v-text="'Save'" @click="clickSave" />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/Layout/Modal'
import IconPencil from '@/components/icons/wflwr/IconPencil.vue'
import ParticipantApi from '@/api/participant/participant'

export default {
  name: 'CancelParticipantModal',
  components: { IconPencil, Modal },
  props: {
    id: Number,
    close: Function,
    onSuccess: Function,
  },
  data() {
    return {
      cancelParticipants: false,
      value: { cancelParticipants: false },

      defaultCheckbox:{
        altLabel: 'block w-full mb-1 font-bold text-sm',
        inner: '$reset inline-block mr-2',
        innerLabel: 'text-[10px] font-bold absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1',
        thumb: 'relative left-0 aspect-square rounded-full transition-all w-5 bg-gray-100',
        track: 'p-0.5 min-w-[3em] relative rounded-full transition-all bg-gray-400 peer-checked:bg-blue-500 peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0',
        valueLabel: 'font-bold text-sm',
        wrapper: 'flex flex-wrap items-center mb-1',
        message: 'text-red-500 mb-1 text-xs'
      },
    }
  },
  methods: {
    clickSave() {
      this.$formkit.submit('cancelForm')
    },
    keyDown(event) {
      if (event.keyCode === 27)
        this.close()
    },
    save(data){
      const notificationId = this.$notification.loading('Participant', 'Cancel participant...')
      ParticipantApi.updateStatus(this.id, data)
        .then(() => {
          this.$notification.updateNotification(notificationId, {
            type: 'success',
            message: `Participant was successfully cancelled`,
            duration: 3000,
          })
          this.close()
          this.onSuccess();
        })
        .catch((error) => {
          this.$notification.updateNotification(notificationId, {
            type: 'error',
            message: error,
          })
          this.close()
        })
    }
  },
  async mounted() {
    document.body.addEventListener('keydown', this.keyDown)
  },
  beforeUnmount() {
    document.body.removeEventListener('keydown', this.keyDown)
  },
}
</script>

<style scoped></style>