import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
        component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                name: 'voucher.parent',
                redirect: {name: 'vouchers'},
                component: () => import('../layout/default/Absolute.vue'),
                props: {
                    subNav: [
                        {to: {name: "vouchers"}, name: "Vouchers", permission:["wflwr.manage.route.vouchers","wflwr.models.voucher.list"]},
                        {to: {name: "voucherOrders"}, name: "Voucher Orders",permission:["wflwr.manage.route.voucherOrders","wflwr.models.voucherOrder.list"]},
                        {to: {name: "voucherKeys"}, name: "Voucher Codes",permission:["wflwr.manage.route.voucherKeys", "wflwr.models.voucherKey.list"]},
                    ],
                },
                children: [
                    {
                        path: 'vouchers',
                        name: 'vouchers',
                        component: () => import('../applications/Vouchers/Vouchers.vue'),
                    },
                    {
                        path: 'voucherOrders',
                        name: 'voucherOrders',
                        component: () => import('../applications/Vouchers/VoucherOrders.vue'),
                    },
                    {
                        path: 'voucherOrders/create',
                        name: 'voucherOrders.create',
                        component: () => import('../applications/Vouchers/Order/VoucherOrderCreate.vue'),
                    },
                    {
                        path: 'voucherOrders/modify/:voucherOrderId',
                        props: true,
                        name: 'voucherOrders.modify',
                        component: () => import('../applications/Vouchers/Order/VoucherOrderCreate.vue'),
                    },
                    {
                        path: 'voucherCodes',
                        name: 'voucherKeys',
                        component: () => import('../applications/Vouchers/VoucherCodes.vue'),
                    },
                    {
                        path: 'voucherCodes/:id',
                        name: 'voucherKey',
                        props: true,
                        redirect: { name: 'voucherKey.detail' },
                        component: () => import('../applications/Vouchers/Code/VoucherCode.vue'),
                        children: [
                            {
                                path: 'detail',
                                name: 'voucherKey.detail',
                                props: true,
                                component: () => import('../applications/Vouchers/Code/VoucherCodeDetail.vue'),
                            },
                        ]
                    },
                ],
            }
        ]
    }
]
