import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
        component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                children: [
                    {
                        path: 'labels',
                        name: 'labels',
                        component: () => import('../applications/Label/Labels.vue'),
                    },
                    {
                        path: 'label',
                        props: false,
                        redirect: { name: 'label.create' },
                        component: () => import('../applications/Label/Label.vue'),
                        children: [
                            {
                                path: 'create',
                                name: 'label.create',
                                props: false,
                                component: () => import('../applications/Label/Label/Detail.vue'),
                            },
                        ]
                    },
                    {
                        path: 'labels/:id',
                        name: 'label',
                        props: true,
                        redirect: { name: 'label.detail' },
                        component: () => import('../applications/Label/Label.vue'),
                        children: [
                            {
                                path: 'detail',
                                name: 'label.detail',
                                props: true,
                                component: () => import('../applications/Label/Label/Detail.vue'),
                            },
                        ]
                    },
                ],
            }
        ]
    }
]