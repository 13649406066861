import Event from '../../models/event/Event'
import Resource from './../Resource'
import {manage} from "@/api/Client";

class EventsApi extends Resource {
    async getEventYears(options) {
        const { data: { data } } = await manage.get(`events/years`, options)
        return data
    }
}

export default new EventsApi('events', Event, null, {
    create: ['models.event.store'],
    update: ['models.event.update'],
    remove: ['models.event.delete']
})
