import {isLoggedIn} from "@/router/guards";

export default [
    {
        path: '/',
        component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                children: [
                    {
                        path: 'globalConfigs',
                        name: 'globalConfigs',
                        component: () => import('../applications/GlobalConfig/GlobalConfigs.vue'),
                    },
                    {
                        path: 'globalConfig',
                        props: false,
                        redirect: { name: 'globalConfig.create' },
                        component: () => import('../applications/GlobalConfig/GlobalConfig.vue'),
                        children: [
                            {
                                path: 'create',
                                name: 'globalConfig.create',
                                props: false,
                                component: () => import('../applications/GlobalConfig/GlobalConfig/Detail.vue'),
                            },
                        ]
                    },
                    {
                        path: 'globalConfigs/:id',
                        name: 'globalConfig',
                        props: true,
                        redirect: { name: 'globalConfig.detail' },
                        component: () => import('../applications/GlobalConfig/GlobalConfig.vue'),
                        children: [
                            {
                                path: 'detail',
                                name: 'globalConfig.detail',
                                props: true,
                                component: () => import('../applications/GlobalConfig/GlobalConfig/Detail.vue'),
                            },
                        ]
                    },
                ],
            }
        ]
    }
]