import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
        component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: 'admin',
                component: () => import('../layout/default/Absolute.vue'),
                children: [
                    {
                        path: 'hero',
                        name: 'admin.hero',
                        props: false,
                        component: () => import('../applications/Admin/Hero/Hero.vue'),
                    },
                    {
                        path: 'commentator',
                        name: 'admin.commentator',
                        props: false,
                        component: () => import('../applications/Admin/Commentator/Commentator.vue'),
                    },
                    {
                        path: 'raceReplay',
                        name: 'admin.raceReplay',
                        props: false,
                        component: () => import('../applications/Admin/RaceReplay/RaceReplay.vue'),
                    },
                    {
                        path: 'anonymize/participant',
                        name: 'admin.anonymize.participant',
                        props: false,
                        component: () => import('../applications/Admin/AnonymizeParticipant/AnonymizeParticipant.vue'),
                    },
                    {
                        path: 'transformTemporary',
                        name: 'admin.transformTemporary',
                        props: false,
                        component: () => import('../applications/Admin/TransformTemporary/TransformTemporary.vue'),
                    },
                ],
            },
        ]
    }
]
