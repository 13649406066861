export default {
    namespaced: true,
    state() {
        const countries = localStorage.getItem('store.global.countries')
        const currencies = localStorage.getItem('store.global.currencies')
        const raceReplayURL = localStorage.getItem('store.global.raceReplayURL')

        return {
            countries: countries ? JSON.parse(countries) : [],
            currencies: countries ? JSON.parse(currencies) : [],
            raceReplayURL: raceReplayURL ? raceReplayURL : null,
        }
    },
    mutations: {
        setCountries(state, payload) {
            state.countries = payload.sort((a, b) => a.attributes.iso2.localeCompare(b.attributes.iso2))
            localStorage.setItem('store.global.countries', JSON.stringify(state.countries))
        },
        setCurrencies(state, payload) {
            state.currencies = payload
            localStorage.setItem('store.global.currencies', JSON.stringify(state.currencies))
        },
        setRaceReplayURL(state, payload) {
            state.raceReplayURL = payload
            localStorage.setItem('store.global.raceReplayURL',state.raceReplayURL)
        }
    },
}