class Portal {

    install(app) {
        app.directive('portal', {
            mounted: (el) => document.body.appendChild(el),
            unmounted: (el) => document.body.removeChild(el),
        })
    }

}

const portal = new Portal()
export default portal