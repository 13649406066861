import { isLoggedIn } from "./guards";

export default [
    {
        path: '/',
        component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                children: [
                    {
                        path: 'tracks',
                        name: 'tracks',
                        component: () => import('../applications/Tracks/Tracks.vue'),
                    },
                    {
                        path: 'tracks/:id',
                        name: 'track',
                        props: true,
                        component: () => import('../applications/Tracks/Track.vue'),
                    }
                ]
            }
        ]
    }
]