import {isLoggedIn} from "./guards";
import * as Modal from "@/components/Modals";
import IotApi from "@/api/iot/iot";

export default [
    {
        path: '/info-system',
        name: 'external.info',
        beforeEnter() {
            let a = atob("d29ybGRydW4=")
            let b = atob("QmV2dV9Pd3U5NQ==")
            Modal.confirm("Info System", `Username: <b>`+a+`</b> </br> Password: <b>`+b+`</b>`, async (confirm) => {
                if (confirm) {
                    location.href = 'https://'+window.location.hostname+'/info'
                }
            })
        }
    },
    {
        path: '/live-system',
        name: 'external.live',
        beforeEnter() {
            location.href = process.env.VUE_APP_WFLWR_BASE_URL+'/live'
        }
    },
]