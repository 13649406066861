import {isLoggedIn} from "./guards";

export default [
    {
                path: '/',
                component: () => import('../layout/default/Absolute.vue'),
                beforeEnter: isLoggedIn,
                children: [
                    {
                        path: 'announcer',
                        name: 'announcer',
                        component: () => import('../applications/Announcer/Announcer.vue'),
                    }
                ]

    }
]