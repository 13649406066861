import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
        component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                props: {
                    subNav: [
                        {to: {name: "donations"}, name: "Donations", permission:["wflwr.manage.route.donations","wflwr.models.donation.list"]},
                        {to: {name: "participantArticleBooster"}, name: "Booster",permission:["wflwr.manage.route.participantArticleBooster","wflwr.models.participantArticle.list"]},
                    ],
                },
                children: [
                    {
                        path: 'donations',
                        name: 'donations',
                        component: () => import('../applications/Donations/Donations.vue'),
                    },
                    {
                        path: 'Booster',
                        name: 'participantArticleBooster',
                        component: () => import('../applications/ParticipantArticle/ParticipantArticleBoosters.vue'),
                    },
                ]
            },
        ]
    }
]