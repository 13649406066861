import * as Vue from 'vue'
import App from './App.vue'
import './index.css'
import i18n from './i18n'
import store from './store'
import router from './router'
import Permissions from './permissions'
import Gate from './permissions/Gate'
import {plugin as formkitPlugin, defaultConfig as formkitDefaultConfig, createInput} from '@formkit/vue'
import { createProPlugin, inputs } from '@formkit/pro'
import { genesisIcons } from '@formkit/icons';
import { generateClasses } from "@formkit/themes";
import SearchSelect from "@/components/Select/SearchSelect";
import EventBus from '@/helpers/EventBus'
import LabelSelector from "@/components/labelSelector/LabelSelector";
import Notification from "@/components/notification";
import ClickOutside from "@/directives/ClickOutside";
import Portal from "@/directives/Portal";
import Drag from '@/directives/Drag'
import MultiLanguageSelect from "@/components/Select/MultiLanguageSelect.vue";
import { createAutoHeightTextareaPlugin } from '@formkit/addons'
import { rootClasses } from "./formKitParticipantTheme";
import wflwrFormkitTheme from './assets/css/wflwr-formkit-theme'
import { fileSize, fileDimensionMax, fileDimensionMin } from "./helpers/FormKit/rules"
import VueTippy from "vue-tippy"

const gate = new Gate({
    permissionPrefix: '',
    store
})

const formkitProPlugin = createProPlugin(process.env.VUE_APP_FORMKIT_PRO_KEY, inputs)

const app = Vue.createApp(App)
app.config.globalProperties.$notification = Notification
app.config.globalProperties.eventBus = EventBus
ClickOutside.install(app)
Portal.install(app)
Drag.install(app)
app.use(i18n)
app.use(store)
app.use(router)
app.use(VueTippy)
app.use(Permissions, {gate} )
app.use(formkitPlugin, formkitDefaultConfig({
    config: {
        rootClasses: rootClasses,
        classes: generateClasses(wflwrFormkitTheme),
    },
    icons: {
        ...genesisIcons,
    },
    plugins: [
        formkitProPlugin,
        createAutoHeightTextareaPlugin(),
    ],
    rules: {
        fileSize,
        fileDimensionMax,
        fileDimensionMin,
    },
    inputs: {
        selectLabel: createInput(LabelSelector, {
            props: ['disableManage'],
        }),
        searchSelect: createInput(SearchSelect, {
            props: ['options','value','allowEmpty','placeholder','selectFirstIfOnlyOption'],
        }),
        multiLanguage: createInput(MultiLanguageSelect, {
            props: ['value', 'options'],
        }),
    }
}))

router.afterEach((to, from) => {
    store.commit('router/change', to, from)
})
router.beforeEach((to, from, next) => {
    // set page title to current path name
    let title = to.title ?? (to.path && to.path !== "/" && (to.path[0] === "/" ? to.path.slice(1) : to.path).split("/").map(item => item[0]?.toUpperCase() + item.slice(1)).join(' | '))
    document.title =  title ? ("RMS - " + title[0].toUpperCase() + title.slice(1)) : 'RMS';
    next();
});

const vm = app.mount('#app')
