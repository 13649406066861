class Drag {
    
    runIf = (condition, run) => condition && run()

    install(app) {
        
        app.directive('drag', (el, binding) => {
            const { start, end, over, drop, leave, suppress, draggable } = binding.value

            el.setAttribute("draggable", draggable ?? false)

            this.runIf(start, () => el.addEventListener('dragstart', start))
            this.runIf(end, () => el.addEventListener('dragend', end))
            this.runIf(over, () => el.addEventListener('dragover', over))
            this.runIf(drop, () => el.addEventListener('drop', drop))
            this.runIf(suppress, () => el.addEventListener('dragsuppress', suppress))
            this.runIf(leave, () => el.addEventListener('dragleave', leave))
        })
    }

}

export default new Drag()