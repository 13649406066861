<template>
  <Modal
      title="Download CustomReport"
      :close="close"
  >
    <template v-slot:icon>
      <IconDownload />
    </template>
    <template v-slot:content>
      <FormKit type="form" id="form" :actions="false">
        <FormKitSchema :schema="formkitSchema" />
      </FormKit>
    </template>
    <template v-slot:footer>
      <FormKit type="button" @click="downloadReport">
        Download
      </FormKit>
    </template>
  </Modal>
</template>

<script>
import { downloadBlob } from "@/helpers/FileHelper";
import CustomReportApi from "@/api/customReport/customReport";

import { FormKitSchema } from "@formkit/vue";
import Modal from '@/components/Modals/Layout/Modal';
import IconDownload from "@/components/icons/wflwr/IconDownload.vue";

export default {
  name: 'DownloadCountryCustomReportModal',
  components: {
    FormKitSchema,
    Modal,
    IconDownload,
  },
  props: {
    customReport: Object,
    close: Function,
  },
  computed: {
    formkitSchema() {
      return this.customReport.attributes.fields
          .map(({ field, type, label, default: defaultValue, ...rest }) =>
              this.inputProps(field, type, label, defaultValue, rest));
    },
    countries() {
      return [
        { label: 'Select a country', value: null },
        ...this.$store.state.global.countries.map(({ attributes }) => ({
          label: attributes.name,
          value: attributes.iso2,
        })),
      ];
    },
  },
  methods: {
    inputProps(name, type, label, defaultValue, rest) {
      const props = {
        name,
        label,
        ...(!defaultValue ? ({
          validation: 'required',
        }) : ({
          value: defaultValue,
        })),
        validationVisibility: 'live',
        ...rest,
      };
      switch (type) {
        case 'country_select':
          props['$formkit'] = 'select';
          props['options'] = this.countries;
          break;
        default:
          props['$formkit'] = type;
          break;
      }
      return props;
    },
    downloadReport() {
      const form = this.$formkit.get('form');
      if (!form.context.state.valid) {
        this.$notification.warning('Download CustomReport', 'Please complete all required fields');
        return;
      }
      CustomReportApi.download(this.customReport.id, form.value, {
        responseType: 'blob',
      }).then(response => {
        const fileName = response.headers["content-disposition"].split("filename=")[1];

        downloadBlob(new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }), fileName);

        this.close()
      }).catch(error => {
        this.$notification.error('Export failed', error);
      });
    },
  },
}
</script>