<template>
  <RefundChargebackModal :id="id" :close="close" title="Voucher Order" :model="model" :showCancelPayment="showCancelPayment">
    <template v-slot:end>
      <FormKit type="checkbox" label="Delete Voucher Keys" name="deleteVoucherKeys" :value="false" />
    </template>
  </RefundChargebackModal>
</template>

<script>
import RefundChargebackModal from '@/components/Modals/Layout/RefundChargeback/RefundChargebackModal.vue'
import VoucherOrderApi from "@/api/vouchers/vouchersOrders";

export default {
  name: 'RefundChargebackVoucherOrderModal.vue',
  props: {
    id: Number,
    close: Function,
    showCancelPayment: Boolean
  },
  components:{
    RefundChargebackModal
  },computed:{
    model() {
      return VoucherOrderApi
    }
  }
}
</script>

<style scoped></style>