import {auth, manage} from '../Client'

export function csrf() {
  return auth.get('csrf-cookie')
}

export function login(credentials, options) {
  return auth.post('login', credentials, options)
}

export function logout(options) {
  return auth.post('logout', options)
}

export function changePassword(credentials, options) {
  return auth.put('password/change', credentials, options)
}

export async function getUserMe(options) {
  const data = await manage.get('users/me', options)
  return data.data;
}