import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
            component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                props: {
                    subNav: [
                        {to: {name: "axs"}, name: "AX"},
                        {to: {name: "axTriggers"}, name: "AX Triggers"},
                    ]
                },
                children: [
                    {
                        path: 'ax',
                        name: 'axs',
                        component: () => import('../applications/AX/AXs.vue'),
                    },
                    {
                        path: 'axTrigger',
                        name: 'axTriggers',
                        component: () => import('../applications/AX/AXTriggers.vue'),
                    },
                    {
                        path: 'axTrigger/create',
                        name: 'axTriggers.create',
                        props: true,
                        component: () => import('../applications/AX/Trigger/AXTriggerCreate.vue'),
                    },
                    {
                        path: 'axTrigger/:id',
                        name: 'axTriggers.detail',
                        props: true,
                        component: () => import('../applications/AX/Trigger/AXTriggerDetail.vue'),
                    },
                    {
                        path: 'ax/:id',
                        name: 'ax',
                        props: true,
                        redirect: { name: 'ax.script' },
                        component: () => import('../applications/AX/AX.vue'),
                        children: [
                            {
                                path: 'detail',
                                name: 'ax.script',
                                props: true,
                                component: () => import('../applications/AX/AX/Script.vue'),
                            }
                        ]
                    },
                ],
            }
        ]
    }
]