import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
        component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                children: [
                    {
                        path: 'emailCampaigns',
                        name: 'emailCampaigns',
                        component: () => import('../applications/EmailCampaign/EmailCampaigns.vue'),
                    },
                    {
                        path: 'emailCampaigns/:campaign/wizard',
                        name: 'emailCampaigns.wizard',
                        props: true,
                        component: () => import('../applications/EmailCampaign/wizard/EmailCampaignWizard.vue'),
                    },
                ]
            }
        ]
    }
]