import Model from '../Model'
import User from '../system/User'
import Voucher from "@/models/voucher/Voucher";

export default class VoucherOrder extends Model {
    static relationMapping = {
        'user': User,
        'voucher': Voucher
    }

}
