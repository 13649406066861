import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
        component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                name: 'sponsors.parent',
                redirect: {name: 'sponsors.country'},
                component: () => import('../layout/default/Absolute.vue'),
                props: {
                    subNav: [
                        {to: {name: "sponsors.country"}, name: "Country View"},
                        {to: {name: "sponsors.event"}, name: "Event View"},
                    ]
                },
                children: [
                    {
                        path: 'sponsors/country',
                        name: 'sponsors.country',
                        component: () => import('../applications/Sponsors/CountryView.vue'),
                    },
                    {
                        path: 'sponsors/event',
                        name: 'sponsors.event',
                        component: () => import('../applications/Sponsors/EventView.vue'),
                    },
                ]
            }
        ]
    }
]