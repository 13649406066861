import ApiResource from './ApiResource'
import { manage } from './Client'
import Model from '../models/Model'

function containsResources(data) {
  if (data === null || !Array.isArray(data) || !data.length) {
    return false
  }

  const [resource] = data
  if (typeof resource !== 'object') {
    return false
  }

  return 'id' in resource && 'attributes' in resource
}

export function mapRelations(data) {
  return Object.entries(data).reduce((mappedRelations, [relation, items]) => ({
    ...mappedRelations,
    [relation]: containsResources(items) ? Model.collection(items) : items
  }), {})
}

export default class Resource extends ApiResource {
  async create(options) {
    const {
      data: {
        data, included, formConfig, ...rest
      }
    } = await manage.get(`${this.resource}/create`, options)
    return {
      data: this.model.create(data, included),
      formConfig: mapRelations(formConfig),
      ...rest
    }
  }

  async edit(id, options) {
    const {
      data: {
        data, included, formConfig, ...rest
      }
    } = await manage.get(`${this.resource}/${id}/edit`, options)
    return {
      data: this.model.create(data, included),
      formConfig: mapRelations(formConfig),
      ...rest
    }
  }

  async versions(id, params) {
    const {
      data: { data, included, ...rest }
    } = await manage.get(`${this.resource}/${id}/versions`, { ...this.options, params })
    return {
      data: Model.collection(data, included),
      ...rest
    }
  }
}
