<template>
  <RefundChargebackModal :id="id" :close="close" title="Donation" :model="model" :showCancelPayment="showCancelPayment">
  </RefundChargebackModal>
</template>

<script>
import RefundChargebackModal from '@/components/Modals/Layout/RefundChargeback/RefundChargebackModal.vue'
import DonationApi from "@/api/donation/donation";

export default {
  name: 'RefundChargebackDonationModal.vue',
  props: {
    id: Number,
    close: Function,
    showCancelPayment: Boolean
  },
  components:{
    RefundChargebackModal
  },computed:{
    model() {
      return DonationApi
    }
  }
}
</script>

<style scoped></style>