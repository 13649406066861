<template>
  <template v-if="initDone">
    <div id="notifications"></div>
    <div
      v-if="serviceWorkerState == 'waiting'"
      class="absolute z-50 top-0 right-0 bottom-0 left-0 bg-white flex items-center justify-center text-3xl">
      <div class="uppercase">
        new worker available, please restart your browser!
      </div>
    </div>
    <div class="absolute z-50 bg-white right-0 bottom-0">
      ServiceWorker: {{ serviceWorkerState }}
      {{ $store.state.race.updateInterval }}
    </div>
    <router-view />
  </template>
  <template v-else> init rms... please wait </template>
</template>

<style lang="scss"></style>

<script>
import RaceUpdateApi from './api/race/raceUpdate'
import EventsApi from "@/api/event/event";
import CountryApi from "@/api/system/country";
import CurrencyApi from "@/api/system/currency";

export default {
  name: 'App',
  data() {
    return {
      serviceWorker: null,
      serviceWorkerState: null,
      initDone: true,
      loadRaceUpdateIsRunning: false,

      intervalRaceUpdate: null,
    }
  },
  watch: {
    '$store.state.auth.user'(){
      if( this.$store.state.auth.user ) {
        this.init()
      }else{
        clearInterval(this.intervalRaceUpdate)
      }
    }
  },
  methods: {
    async init(){
      await this.initEventYearService();
      await this.initServiceWorker();
      await this.initEvents();
      //possible to check if already saved in local storage?
      await this.initGlobals();
      this.$formkit.setLocale(navigator.language ?? this.$store.state.layout.numberLocaleFormat)
      this.initDone = true;

      await this.initRaceUpdate();
      if( !this.$gate.hasAll('wflwr.api.manage.eventsUpdate') ) {
        this.$store.commit("layout/toggleSidebarRight", false)
      }
    },
    async initEventYearService(){
      const data = await EventsApi.getEventYears();
      this.$store.commit("race/setEventYears", data.years)
      this.$store.commit("race/setDefaultEventYear", data.default)
      if(!this.$store.state.race.eventYear){
        this.$store.commit("race/setEventYear", data.default)
      } else if(!this.$gate.hasAll('wflwr.manage.route.extended-options') && this.$store.state.race.eventYear !== data.default){
        await this.$store.commit("race/setEventYear", data.default)
        window.location.reload();
      }
    },
    async initEvents() {
      const data = await RaceUpdateApi.init();
      this.$store.commit("race/init", data)
    },
    async initGlobals() {
      const currencies = (await CurrencyApi.list()).data
      const countries = (await CountryApi.list()).data
      this.$store.commit("global/setCurrencies", currencies)
      this.$store.commit("global/setCountries", countries)
    },
    async initRaceUpdate() {
      const interval = this.$store.state.race.updateInterval;
      await this.loadRaceStatus(0);
      this.intervalRaceUpdate = setInterval(this.loadRaceStatus.bind(this, interval), interval);
    },
    async initServiceWorker() {
      if (!('serviceWorker' in navigator)) {
        // The current browser doesn't support service workers.
        // Perhaps it is too old or we are not in a Secure Context.
        console.log("service worker not available");
        this.serviceWorkerState = 'browser issue'
        return;
      }


      this.serviceWorkerState = 'init';

      try {
        const registration = await navigator.serviceWorker.register('serviceWorker.js', {
          scope: '/'
        })

        this.serviceWorkerState = 'registered';

        if (registration.installing) {
          this.serviceWorker = registration.installing;
          this.serviceWorkerState = 'installing';
        } else if (registration.waiting) {
          this.serviceWorker = registration.waiting;
          this.serviceWorkerState = 'waiting';
        } else if (registration.active) {
          this.serviceWorker = registration.active;
          this.serviceWorkerState = 'active';
        }

        if (this.serviceWorker) {
          // logState(serviceWorker.state);
          this.serviceWorker.addEventListener('statechange', function (e) {
            // logState(e.target.state);
            console.log("state change", e.target.state );
            this.serviceWorkerState += ' ' + e.target.state
          }.bind(this));
        }
      }catch (e){
        console.log("error registering....", e);
      }
    },
    async loadRaceStatus(interval) {
      console.log(`Interval reached every ${interval / 1000}s`, this.serviceWorkerState);
      if( this.loadRaceUpdateIsRunning ){
        console.log(`loading overlap, ignore this round`)
        return;
      }
      try {
        this.loadRaceUpdateIsRunning = true;
        if( this.$gate.hasAll('wflwr.api.manage.globalUpdate') ) {
          this.$store.commit(
              "race/updateGlobal", await RaceUpdateApi.updateGlobal()
          )
        }

        if( this.$gate.hasAll('wflwr.api.manage.eventsUpdate') ) {
          this.$store.commit(
              "race/updateEvents",
              await RaceUpdateApi.updateEvents()
          )
        }
      }catch (exception){
        console.log('error on loading race update', exception)
      }

      this.loadRaceUpdateIsRunning = false
    }
  }
}
</script>