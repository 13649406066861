<template>
  <Multiselect :options="$options"  v-model="value" :multiple="true" :taggable="false" track-by="id" :show-labels="false" label="name" class="multiLanguage">
    <template #tag="props">
        <span class="bg-cli-primary py-1 px-2 rounded text-white" v-text="props.option.name" />
    </template>
    <template slot="option" slot-scope="props">
      <div class="item">
        <span v-text="props.option.name" />
      </div>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: 'MultiLanguageSelect',
  components: {
    Multiselect,
  },
  props: ['context'],
  data() {
    return {
      value: this.context.value || [],
    }
  },
  computed: {
    $options() {
      return this.context.options
    }
  },
  watch: {
    value(newValue) {
      this.context.node.input(newValue)
    },
  },
}
</script>

<style lang="scss">
.multiLanguage {

  .multiselect__option--selected.multiselect__option--highlight,
  .multiselect__option--highlight {
    background: #f3f3f3;
    color: #35495e;
  }

  .multiselect__tags-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: -3px;
  }

}
</style>