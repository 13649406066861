async function fileSize(node, min, max) {
    const check = (a, b) => b && a > parseInt(b)
    for (const entry of node._value.filter(entry => entry.file)) {
        const size = entry.file.size
        if (check(size, min) && check(size, max)) {
            return true
        }
    }
    return false
}

export {
    fileSize,
}