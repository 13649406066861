import router from './../../router'

function compressRoute(route) {
    // Only need name, params and query - drop everything else
    return {
        name: route.name,
        params: route.params,
        query: route.query,
    }
}

export default {
    namespaced: true,
    state: () => ({
        historyByRouteName: {},
        currentPathResolved: {},
    }),
    mutations: {
        change(state, to, from){
            if( to.name ) {
                // store last history entry by route name
                state.historyByRouteName[to.name] = compressRoute(to)
            }

            let i = 0;
            const pathSplitted = to.path.split('/')
            state.currentPathResolved = pathSplitted.reduce((reduceState, part) => {
                reduceState.pathParts.push(part)
                const path = reduceState.pathParts.join('/')

                const route = router.resolve(path !== '' ? {path} : {name: 'home'});

                reduceState.matchingRoutes.push({
                    number: i++,
                    part,
                    path,
                    route: compressRoute(route),
                    history: state.historyByRouteName[ route.name ]
                })

                return reduceState
            }, {
                path: '/',
                pathParts: [],
                matchingRoutes: []
            })

            // Todo: build cleanup for historyByRouteName and history
        }
    }
}
