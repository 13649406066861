import {getUserMe, login, logout} from "../../api/auth/auth";

export default {
    namespaced: true,
    state: () => ({
        user: null,
        permissions: null
    }),
    mutations: {
        login(state, user = null){
            state.user = user
        },
        logout(state){
            state.user = null
        },
        setPermissions(state, permissions){
            state.permissions = permissions
        },
        resetPermissions(state){
            state.permissions = null
        },
    },
    actions: {
        async autoLogin(context){
            const user = await getUserMe()
            context.commit('login', user.data)
            context.commit('setPermissions', user.data.attributes.permissions)
        },
        async login(context, credentials) {
            await login(credentials)
            await context.dispatch('autoLogin')
        },
        async logout(context){
            await logout()
            context.commit('logout')
            context.commit('resetPermissions')
        },
    }
}