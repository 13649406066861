import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
            component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                children: [
                    {
                        path: 'print/startnumbers',
                        name: 'print.startnumbers',
                        component: () => import('../applications/Print/PrintList.vue'),
                    },
                    {
                        path: 'print/startnumbers/events/:id',
                        name: 'print.startnumbers.event',
                        props: true,
                        redirect: { name: 'print.startnumbers.event.detail' },
                        component: () => import('../applications/Print/PrintEvent.vue'),
                        children: [
                            {
                                path: 'detail',
                                name: 'print.startnumbers.event.detail',
                                props: true,
                                component: () => import('../applications/Print/Event/Detail.vue'),
                            },
                            {
                                path: 'tag-checker/:batchId',
                                name: 'print.startnumbers.event.startnumberChecker.batch',
                                props: true,
                                component: () => import('../applications/Print/Event/StartnumberChecker.vue'),
                            }
                        ]
                    },
                ],
            }
        ]
    }
]