import RaceUpdateApi from "@/api/race/raceUpdate";

export default {
    namespaced: true,
    state() {
        const favEvents = localStorage.getItem('store.race.favEvents')
        const updateInterval = localStorage.getItem('store.race.updateInterval')
        const raceTypeFilter = localStorage.getItem('store.race.raceTypeFilter')
        const raceTypeFilters = localStorage.getItem('store.race.raceTypeFilters')
        const eventYear = localStorage.getItem('store.race.eventYear')
        const eventYears = localStorage.getItem('store.race.eventYears')
        const environment = localStorage.getItem('store.race.environment')

        return {
            updateInterval: ( updateInterval ? JSON.parse(updateInterval) : 60*1000),
            events: {},
            favoriteEvents: ( favEvents ? JSON.parse(favEvents) : [] ),
            raceTypeCounters: null,
            raceTypeFilter: raceTypeFilter ? raceTypeFilter : null,
            raceTypeFilters: raceTypeFilters ?  JSON.parse(raceTypeFilters) : [],
            guntime: null,
            globalCatcherCar: {
                position: null,
                kmh: null,
                kmhProfile: [
                    {kmh: 0, duration: 30*60 },
                    {kmh: 14, duration: 30*60 },
                    {kmh: 15, duration: 30*60 },
                    {kmh: 16, duration: 30*60 },
                    {kmh: 17, duration: 30*60 },
                    {kmh: 18, duration: 30*60 },
                    {kmh: 22, duration: 30*60 },
                    {kmh: 26, duration: 30*60 },
                    {kmh: 30, duration: 30*60 },
                    {kmh: 34, duration: 2*60*60 },
                ]
            },
            eventYear: eventYear
                ? ( typeof eventYear === 'string' ? parseInt(eventYear) : null )
                : null,
            eventYears: eventYears? JSON.parse(eventYears) : [],
            defaultEventYear: null,
            environment: environment,
        }
    },
    mutations: {
        init(state, payload){
            const events = {};
            payload.events.forEach((event) => {
                event.track = Object.freeze(event.track) // avoid the tracks object to be reactive in vue, other wise this lead to a huge overhead as tracks have a lot of elements
                events[ event.id ] = event
            })
            state.events = events
            state.environment = payload.environment
        },
        updateGlobal(state, payload){
            state.raceTypeCounters = payload.raceTypeCounters
            state.guntime = payload.guntime
            state.globalCatcherCar = payload.catcherCar
        },
        updateEvents(state, payload){
            payload.events.forEach((event) => {
                if( typeof state.events[ event.id ] === "undefined" ){
                    console.log("error updating event!", event );
                    return;
                }
                state.events[ event.id ].status = event.status;
                state.events[ event.id ].raceStatus = event.raceStatus;
            })
        },
        addFavEvent(state, id){
            if( !state.favoriteEvents.includes( id ) ){
                state.favoriteEvents.push(id);
                localStorage.setItem('store.race.favEvents', JSON.stringify(state.favoriteEvents))
            }
        },
        removeFavEvent(state, id){
            const index = state.favoriteEvents.indexOf( id )
            if( index != -1 ){
                state.favoriteEvents.splice(index, 1);
                localStorage.setItem('store.race.favEvents', JSON.stringify(state.favoriteEvents))
            }
        },
        setUpdateInterval(state, interval){
            state.updateInterval = interval;
            localStorage.setItem('store.race.updateInterval', JSON.stringify(interval))
            window.location.reload(); // reload the page, so we don't need to handle resetting the running fetch interval ;-)
        },
        setRaceTypeFilter(state, raceType){
            state.raceTypeFilter = raceType

            let raceTypes = []
            switch (raceType){
                case 'all':
                    raceTypes = ['flagship', 'individual', 'organized']
                    break;
                case 'app':
                    raceTypes = ['individual', 'organized']
                    break;
                case 'flagship':
                case 'organized':
                case 'individual':
                    raceTypes = [raceType]
                    break;
            }

            state.raceTypeFilters = raceTypes

            localStorage.setItem('store.race.raceTypeFilter', state.raceTypeFilter)
            localStorage.setItem('store.race.raceTypeFilters', JSON.stringify(state.raceTypeFilters))
        },
        setEventYears(state, payload) {
            state.eventYears = payload
            localStorage.setItem('store.race.eventYears', JSON.stringify(state.eventYears))
        },
        setDefaultEventYear(state, payload) {
            state.defaultEventYear = payload
        },
        setEventYear(state, payload) {
            if(state.eventYear!=payload){
                state.eventYear = payload
                localStorage.setItem('store.race.eventYear', state.eventYear)
            }
        },
        removeEventYear(state){
            state.eventYear = state.defaultEventYear
            localStorage.setItem('store.race.eventYear', state.eventYear)
        },
    },
    actions: {
        isFavEvent(context, id){
            return context.state.favoriteEvents.includes( id )
        },
        async addOrRemoveFavEvent(context, id) {
            const exists = await context.dispatch('isFavEvent', id)
            if (!exists) {
                context.commit('addFavEvent', id)
            } else {
                context.commit('removeFavEvent', id)
            }
        },
    },
    getters: {
        filteredEvents(state){
            return Object.fromEntries(Object.entries(state.events)
                .filter((group) => {
                    const event = group[1]
                    return state.raceTypeFilters.length <= 0 || state.raceTypeFilters.includes( event.raceType )
                }))
        }
    }
}
