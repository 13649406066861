import Resource from './../Resource'
import Participant from "../../models/participant/Participant";
import {manage} from "@/api/Client";

class ParticipantApi extends Resource {
    async addStartnumber(participantId, payload, options) {
        const { data: { data } } = await manage.post(`participants/${participantId}/startnumbers`, payload, options)
        return data
    }
    async getCreateEvents(options) {
        const { data: { data } } = await manage.get(`participants/listPossibleEvents`, options)
        return data
    }
    async getDetections(participantId) {
        return await manage.get(`participants/${participantId}/detections`)
    }

    async changeUser(participantId, userId, options) {
        const { data: { data } } = await manage.patch(`participants/${participantId}/changeUser`, {
            user: userId,
        }, options)
        return data
    }

    async updateStatus(participantId, payload, options) {
        const { data: { data } } = await manage.put(`participants/${participantId}/status`, payload, this._options(options))
        return data
    }

    async createForm(options) {
        return manage.get(`participants/create`, options)
    }

    async store(payload, options) {
        return manage.post(`participants`,payload, options)
    }

    async updateStatus(participantId, payload, options) {
        const { data: { data } } = await manage.put(`participants/${participantId}/status`, payload, this._options(options))
        return data
    }

}

export default new ParticipantApi('participants', Participant, null, {
    create: ['models.participant.store'],
    update: ['models.participant.update'],
    remove: ['models.participant.delete']
})
