import {isLoggedIn} from "./guards";

export default [
    {
        path: '/',
        component: () => import('../layout/default/Default'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                component: () => import('../layout/default/Absolute.vue'),
                children: [
                    {
                        path: 'groups',
                        name: 'groups',
                        component: () => import('../applications/Group/Groups.vue'),
                    },
                    {
                        path: 'groups/:id',
                        name: 'groups.detail',
                        props:true,
                        component: () => import('../applications/Group/Group/Group.vue'),
                    },
                ]
            },
        ]
    }
]