<template>
  <Modal :title="`Edit ${type} #${id}`" :close="close">
    <template v-slot:icon>
      <IconPencil />
    </template>
    <template v-slot:content>
      <FormKit v-model="value" v-if="value" type="form" id="cancelForm" :actions="false" @submit="save">
        <FormKit type="checkbox" label="Cancel Payment" name="cancelPayment" v-model="value.cancelPayment"  @change="updateValues" v-if="showCancelPayment"/>
        <div v-if="value.cancelPayment" class="mb-4">
          <FormKit type="text" v-if="hasRemark" validation="required" label="Remark" name="remark" />
          <div class="mt-2">
            <FormKit type="select" label="Type" name="type" :value="'refund'" :options="['refund', 'chargeback']" @change="updateValues" />
          </div>
          <div v-if="value.type === 'refund'" class="border-2 border-gray-100 rounded-xl p-2 m-2">
            <FormKit type="date" validation="required" label="Refund Date" name="refundDate" />
            <div class="grid grid-cols-2 gap-2 mt-2">
              <FormKit type="number" :delay="500" label="Refund Value" @input="refundValuesChanged($event,null)" name="refundValue" validation="number|required|min:0" />
              <FormKit label="Currency" type="autocomplete" @input="refundValuesChanged(null,$event)" :allowEmpty="false" name="refundCurrencyIso3" placeholder="Select Currency" :options="currencies" validation="required" />
            </div>
            <div class="grid grid-cols-2 gap-2 mt-2">
              <FormKit type="number" label="Refund Fee" name="refundFee" validation="number|min:0" placeholder="Calculated automatically" :disabled="!overwriteFee">
                <template #help="{ value, state }">
                  <div class="flex flex-row items-center ml-1" v-if="loadingCalculatedValues">
                    <Spinner class="w-3 h-3 mr-2" />
                    <p class="text-sm">Loading...</p>
                  </div>
                </template>
              </FormKit>
              <FormKit label="Refund Fee Currency" type="autocomplete" :allowEmpty="true" name="refundFeeCurrencyIso3" :options="currencies"  placeholder="Calculated automatically" :disabled="!overwriteFee">
                <template #help="{ value, state }">
                  <div class="flex flex-row items-center ml-1" v-if="loadingCalculatedValues">
                    <Spinner class="w-3 h-3 mr-2" />
                    <p class="text-sm">Loading...</p>
                  </div>
                </template>
              </FormKit>
            </div>
            <FormKit type="toggle" label="Overwrite Fee" name="overwriteFee" v-model="overwriteFee"/>
          </div>
          <div v-if="value.type === 'chargeback'" class="border-2 border-gray-100 rounded-xl p-2 m-2">
            <FormKit type="date" validation="required" label="Chargeback Date" name="chargebackDate" />
            <div class="grid grid-cols-2 gap-2 mt-2">
              <FormKit type="number" @input="chargebackValuesChanged($event,null)" label="Chargeback Value" name="chargebackValue" validation="number|required|min:0" />
              <FormKit label="Currency *" @input="chargebackValuesChanged(null,$event)" type="autocomplete" :allowEmpty="false" name="chargebackCurrencyIso3" placeholder="Select Currency" :options="currencies" validation="required" />
            </div>
            <div class="grid grid-cols-2 gap-2 mt-2">
              <FormKit label="Chargeback Fee" type="text" name="chargebackFee" placeholder="Calculated automatically" :disabled="!overwriteFee">
                <template #help="{ value, state }">
                <div class="flex flex-row items-center ml-1" v-if="loadingCalculatedValues">
                  <Spinner class="w-3 h-3 mr-2" />
                  <p class="text-sm">Loading...</p>
                </div>
              </template>
              </FormKit>
              <FormKit label="Chargeback Fee Currency" :allowEmpty="true" type="text" name="chargebackFeeCurrencyIso3" placeholder="Calculated automatically" :disabled="!overwriteFee">
                <template #help="{ value, state }">
                  <div class="flex flex-row items-center ml-1" v-if="loadingCalculatedValues">
                    <Spinner class="w-3 h-3 mr-2" />
                    <p class="text-sm">Loading...</p>
                  </div>
                </template>
              </FormKit>
            </div>
            <FormKit type="toggle" label="Overwrite Fee" name="overwriteFee" v-model="overwriteFee"/>

          </div>
        </div>
        <slot name="end"/>
      </FormKit>
      <div v-if="!modelValue" class="flex items-center justify-center flex-col">
        <div class="flex items-center justify-center flex-col">
          <Spinner class="w-10 h-10 mb-2" />
          <p class="text-2xl">Loading...</p>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex flex-row gap-4">
        <button class="flex-1 rounded pt-2 pb-2 pr-4 pl-4 text-white bg-cli-primary disabled:bg-gray-200" v-text="'Save'" @click="clickSave" />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/Layout/Modal.vue'
import IconPencil from '@/components/icons/wflwr/IconPencil.vue'
import Spinner from '@/components/Spinner.vue'
import { FormKit } from '@formkit/vue'
import {round} from "lodash";
import ApiResource from "@/api/ApiResource";

export default {
  name: 'RefundChargebackModal',
  components: { Spinner, IconPencil, Modal, FormKit },
  props: {
    id: Number,
    close: Function,
    type: String,
    model: ApiResource,
    hasRemark: false,
    showCancelPayment: true,
    onStatusChange: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      modelValue: null,
      cancelPayment: false,
      cancelParticipants: false,
      value: null,
      loadingCalculatedValues: false,
      overwriteFee: false
    }
  },
  methods: {
    async refundValuesChanged(value,currency) {
      if(!this.overwriteFee) {
        this.loadingCalculatedValues = true;
        let fee = (await this.model.calculateRefund(this.id, {
          params: {
            'type': 'refund',
            'refundValue': value??this.value.refundValue,
            'refundCurrencyIso3': currency??this.value.refundCurrencyIso3
          }
        }))
        this.value.refundFee = round(fee.value, 2);
        this.value.refundFeeCurrencyIso3 = fee.currency_iso3;
        this.loadingCalculatedValues = false;
      }
    },
    async chargebackValuesChanged(value,currency) {
      if(!this.overwriteFee) {
        this.loadingCalculatedValues = true;
        let fee = (await this.model.calculateRefund(this.id, {
          params: {
            'type': 'chargeback',
            'chargebackValue': value??this.value.chargebackValue,
            'chargebackCurrencyIso3': currency??this.value.chargebackCurrencyIso3
          }
        }))
        this.value.chargebackFee = round(fee.value, 2);
        this.value.chargebackFeeCurrencyIso3 = fee.currency_iso3;
        this.loadingCalculatedValues = false;
      }
    },
    async updateValues() {
      this.value.refundValue= this.value.refundValue??this.value.valueGross
      this.value.refundCurrencyIso3= this.value.refundCurrencyIso3??this.value.currencyIso3

      this.value.chargebackValue= this.value.chargebackValue??this.value.valueGross
      this.value.chargebackCurrencyIso3= this.value.chargebackCurrencyIso3??this.value.currencyIso3

      if(!this.value.refundFee && !this.value.chargebackFee){
        if(this.value.type === "chargeback")
          await this.chargebackValuesChanged();
        else
          await this.refundValuesChanged();
      }
    },
    clickSave() {
      this.$formkit.submit('cancelForm')
    },
    keyDown(event) {
      if (event.keyCode === 27)
        this.close()
    },
    save(data){
      const notificationId = this.$notification.loading(this.title, 'Updating payment status...')
      this.model.updateStatus(this.id, data)
        .then(() => {
          this.$notification.updateNotification(notificationId, {
            type: 'success',
            message: `Payment status was successfully changed`,
            duration: 3000,
          })
          this.close()
          this.onStatusChange()
        })
        .catch((error) => {
          this.$notification.updateNotification(notificationId, {
            type: 'error',
            title: 'Error',
            message: error.response.data ?? error,
          })
          this.close()
        })
    }
  },
  computed: {
    currencies() {
      const array = []
      this.$store.state.global.currencies.forEach((currency) => array.push({ label: `${currency.attributes.name} (${currency.attributes.iso3})`, value: currency.attributes.iso3 }))
      return array
    },
  },
  async mounted() {
    this.modelValue = (await this.model.showStatus(this.id)).data
    this.value = {
      cancelPayment: !!(this.modelValue.attributes.refundValue||this.modelValue.attributes.chargebackValue),
      type: this.modelValue.attributes.chargebackValue?"chargeback":"refund",
      cancelParticipants: false,
      ...this.modelValue.attributes,
    }
    if(this.showCancelPayment)
      this.updateValues();
    document.body.addEventListener('keydown', this.keyDown)

  },
  beforeUnmount() {
    document.body.removeEventListener('keydown', this.keyDown)
  },
}
</script>

<style scoped></style>