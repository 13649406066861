<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start sm:flex-col">
              <div class="flex flex-row w-full">
                <div class="mx-auto flex h-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10 mr-4">
                  <IconBase class="w-5 h-5 text-cli-primary-dark">
                    <slot name="icon"></slot>
                  </IconBase>
                </div>
                <h3 class="text-lg font-medium leading-6 text-gray-900 my-2 ml-4 flex-grow" id="modal-title" v-text="title"></h3>
                <div class="mx-auto flex h-12 flex-shrink-0 items-center justify-center cursor-pointer sm:mx-0 sm:h-10 sm:w-10 mr-4" @click="close">
                  <IconBase class="w-5 h-5 text-cli-primary-dark" >
                    <IconClose/>
                  </IconBase>
                </div>
              </div>
              <div class="border-gray-800 border-b-2 my-2 w-full"></div>
              <div class="mt-3 text-left w-full">
                <div class="mt-2 w-full">
                  <slot name="content"></slot>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import race from "@/store/modules/race";
import layout from "@/store/modules/layout";
import IconBase from "@/components/icons/IconBase.vue";
import IconPlus from "@/components/icons/wflwr/IconPlus.vue";
import IconPencil from "@/components/icons/wflwr/IconPencil.vue";
import IconClose from "@/components/icons/wflwr/IconClose.vue";

export default {
  name: "ExtendedRMSOptionsModal.vue",
  components: {IconClose, IconPencil, IconPlus, IconBase},
  computed: {
    layout() {
      return layout
    },
    race() {
      return race
    }
  },
  props: {
    title: String,
    close: Function
  },
  data(){
    return {
    }
  },
}
</script>

<style scoped>

</style>
