import Resource from './../Resource'
import VoucherOrder from "@/models/voucher/VoucherOrder";
import {manage} from "@/api/Client";

class VouchersOrdersApi extends Resource {

  async aboutOrder(id) {
    const { data: data } = await manage.get(`voucherOrders/${id}`)
    return data
  }

  async removeOrder(id) {
    const { data: data } = await manage.delete(`voucherOrders/${id}`)
    return data
  }

  async generatePreliminaryInvoice(id){
    return await manage.get(`voucherOrders/${id}/generatePreliminaryInvoice`)
  }

  async setPaid(id){
    return await manage.post(`voucherOrders/${id}/setPaid`)
  }

  async approveVoucherKeyValue(id, value){
    return await manage.post(`voucherOrders/${id}/approveVoucherKeyValue`, {voucher_key_value: value})
  }

  async showStatus(id, options) {
    const { data: data } = await manage.get(`voucherOrders/${id}/status`, this._options(options))
    return data
  }

  async resendConfirmationEmail(id, options) {
    return await manage.get(`${this.resource}/${id}/resendEmail`, this._options(options));
  }

  downloadInvoice(id){
    return manage.get("/voucherOrders/"+id+'/generateInvoice',{  responseType: 'arraybuffer'}).then(function(response) {
      let blob = new Blob([response.data], { type: 'application/pdf' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.setAttribute('download',
        response.headers["content-disposition"].split("filename=")[1].replaceAll("\"",""));
      link.click()
    })
  }

  async updateStatus(id, content, options) {
    const { data: data } = await manage.put(`voucherOrders/${id}/updateStatus`, content, this._options(options))
    return data
  }

  async getVoucherOrderStats(id, options) {
    const { data: data } = await manage.get(`voucherOrders/${id}/stats`, this._options(options))
    return data
  }

  async calculateRefund(id, options) {
    const { data: data } = await manage.get(`voucherOrders/${id}/calculateRefund`, this._options(options))
    return data
  }

  async getVoucherKeys(id, options) {
    const { data: data } = await manage.get(`voucherOrders/${id}/keys`, this._options(options))
    return data
  }

}

export default new VouchersOrdersApi('voucherOrders', VoucherOrder, null, {
  create: ['models.voucherOrder.create'],
  update: ['models.voucherOrder.update'],
  remove: ['models.voucherOrder.delete'],
})
