import {manage} from "../Client";

class RaceUpdateApi {
    async init(options) {
        const { data: { data } } = await manage.get(`race/init`, options)
        return data
    }

    async updateGlobal(options) {
        const { data: { data } } = await manage.get(`race/global-update`, options)
        return data
    }

    async updateEvents(options) {
        const { data: { data } } = await manage.get(`race/events-update`, options)
        return data
    }

    async mapUpdate(options) {
        const { data: { data } } = await manage.get(`race/map-update`, options)
        return data
    }
}

export default new RaceUpdateApi()
