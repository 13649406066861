<template>
  <Modal :title="`Select Create Type`" :close="close">
    <template v-slot:icon>
      <IconPencil />
    </template>
    <template v-slot:content>
      <p>Please select the type of Participant creation.</p>
      <p class="font-semibold">Default is On-Site registration.</p>
    </template>
    <template v-slot:footer>
      <div class="flex flex-row gap-4 w-full justify-end">
        <button class="w-1/6 rounded pt-2 pb-2 pr-4 pl-4 text-white bg-cli-secondary disabled:bg-gray-200" v-text="'In RMS'" @click="redirectCreate" />
        <button class="w-1/4 rounded pt-2 pb-2 pr-4 pl-4 text-white bg-cli-primary disabled:bg-gray-200 font-semibold" v-text="'On-Site'" @click="redirectLink" />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/Layout/Modal'
import IconPencil from '@/components/icons/wflwr/IconPencil.vue'

export default {
  name: 'CreateParticipantModal',
  components: { IconPencil, Modal },
  props: {
    id: Number,
    close: Function,
  },
  data() {
    return {}
  },
  methods: {
    keyDown(event) {
      if (event.keyCode === 27) this.close()
    },
    redirectLink() {
      this.$router.push({name: 'participant.create-link'})
      this.close()
    },
    redirectCreate() {
      this.$router.push({name: 'participant.create'})
      this.close()
    }
  },
  async mounted() {
    document.body.addEventListener('keydown', this.keyDown)
  },
  beforeUnmount() {
    document.body.removeEventListener('keydown', this.keyDown)
  },
}
</script>

<style scoped></style>